import React, { FormEvent } from 'react';
import {
  useCreateUserMutation,
  useGetMetadataQuery,
} from '../../../store/adminPanel/adminPanel.service';
import { Controller, useForm } from 'react-hook-form';
import { CircularProgress, Paper } from '@mui/material';

import TextField from '@mui/material/TextField';

import { DocumentFilter } from '../../Documents/DocumentsSearch/DocumentFilter';
import { GroupNameFilter } from '../GroupNameFilter/GroupNameFilter';
import '../../Documents/DocumentsSearch/DocumentsSearch.scss';
import { handleApiError } from '../../../store/error.helpers';
import { useSnackbar } from 'notistack';
import { useApiError } from '../../../store/ui/ui.helpers';
import { GroupMetadata } from '../../../store/adminPanel/adminPanel.types';
import LoadingButton from '@mui/lab/LoadingButton';
import { CreateUserPanelRules } from './CreateUserPanel.helpers';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

import './CreateUserPanel.scss';
import { filterUserGroupsValues, sortUsersGroups } from '../AdminPanel.helpers';
import { ApiError } from '../../UploadFiles/MetadataForm/ApiError';
import { ButtonWithIcon } from '../../StaticComponents/ButtonWithIcon';
import { FirstParam } from '../../Documents/DocumentsSearch/DocumentsSearch.types';
import { CheckboxWithLabel } from '../../StaticComponents/CheckboxWithLabel';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { AdminPanelCreatedUserForm } from './CreateUserPanel.types';

export const CreateUserPanel = () => {
  const {
    control,
    getValues,
    setValue,
    watch,
    reset,
    formState: { isValid, isDirty },
  } = useForm<AdminPanelCreatedUserForm>({
    defaultValues: {
      Active: true,
    },
    mode: 'onChange',
  });
  const { enqueueSnackbar } = useSnackbar();
  const { apiError, setApiError, clearApiError } = useApiError();

  const { data, isLoading, isFetching } = useGetMetadataQuery();
  const [update, { isLoading: isCreatingUser }] = useCreateUserMutation();

  const isInputDisabled = isLoading || isFetching || isCreatingUser;

  const selectedEntity = watch('Entity');
  const selectedGroups = watch('UserGroups');

  const filteredUserGroupsValues = filterUserGroupsValues(
    selectedEntity,
    selectedGroups,
    sortUsersGroups(data?.Groups),
    setValue
  );

  const cleanForm = (
    KeepParameters: boolean,
    setValue: UseFormSetValue<AdminPanelCreatedUserForm>
  ) => {
    if (!KeepParameters) {
      reset();
    } else {
      setValue('Email', '');
    }
  };

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    setApiError(undefined);
    const { KeepParameters, ...formValues } = getValues();
    update(formValues)
      .unwrap()
      .then(() => {
        cleanForm(KeepParameters, setValue);
        enqueueSnackbar('User has been created successfully');
      })
      .catch((response) => setApiError(handleApiError(response, true)));
  };

  const handleCancel = () => {
    reset();
    clearApiError();
  };

  if (isLoading || isFetching) {
    return (
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '30px',
          borderRadius: 4,
          marginTop: '35px',
          minWidth: 600,
          height: '30vh',
        }}
      >
        <CircularProgress size={50} />
      </Paper>
    );
  }

  return (
    <div className='admin-panel__create-user'>
      <form className='admin-panel__create-user__form' onSubmit={handleSubmit}>
        <div className='admin-panel__create-user__filters'>
          <Controller
            control={control}
            name='Email'
            defaultValue=''
            rules={CreateUserPanelRules.Email}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                disabled={isInputDisabled}
                className='single-input-modal-name'
                placeholder='Email'
                fullWidth
                size='small'
                variant='outlined'
                helperText={<ErrorMessage error={error} />}
                onChange={(e) => {
                  field.onChange(e);
                  clearApiError();
                }}
              />
            )}
          />
          <Controller
            control={control}
            name='Entity'
            defaultValue=''
            render={({ field }) => (
              <DocumentFilter
                {...field}
                label='Company'
                values={data?.Entities}
                firstParam={FirstParam.NONE}
                disabled={isInputDisabled}
              />
            )}
          />

          <Controller
            control={control}
            name='UserGroups'
            defaultValue={[]}
            rules={CreateUserPanelRules.UserGroups}
            render={({ field, fieldState: { error } }) => (
              <GroupNameFilter
                {...field}
                disabled={isInputDisabled}
                error={error}
                multiple
                label='User group(s)'
                values={filteredUserGroupsValues as unknown as GroupMetadata[]}
              />
            )}
          />

          <Controller
            control={control}
            name='Approver'
            defaultValue=''
            render={({ field }) => (
              <TextField
                {...field}
                disabled={isInputDisabled}
                className='single-input-modal-name'
                placeholder='Approver'
                fullWidth
                size='small'
                variant='outlined'
                onChange={(e) => {
                  field.onChange(e);
                  clearApiError();
                }}
              />
            )}
          />

          <Controller
            control={control}
            name='KeepParameters'
            defaultValue={false}
            render={({ field }) => (
              <CheckboxWithLabel
                {...field}
                checked={field.value}
                onChange={(_, checked) => {
                  field.onChange(checked);
                }}
                label='Keep parameters'
              />
            )}
          />
        </div>
        <div className='admin-panel__create-user__form__error'>
          <ApiError apiError={apiError} />
        </div>

        <div className='admin-panel__create-user__buttons'>
          <ButtonWithIcon
            className='admin-panel__create-user__buttons__cancel-button'
            onClick={handleCancel}
            disabled={!isDirty || isCreatingUser}
          >
            Cancel
          </ButtonWithIcon>
          <LoadingButton
            className='confirm-button'
            variant='contained'
            disabled={!isValid}
            loading={isCreatingUser}
            type='submit'
          >
            Save
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};
