import { SubmitResult } from '../../../../store/files/upload/submit.service.types';

export const uploadConfirmModalDescriptions = {
  clause: ['Clause ', ' clauses ', '"Uploaded clauses"', 'Clauses'],
  document: ['Document ', ' documents ', '"Uploaded documents"', 'Documents'],
  draft_clause: ['Draft clause ', ' draft clauses ', '"Sandbox" section', 'Sandbox'],
  draft_document: ['Document ', ' documents ', '"Sandbox" section', ''],
};

export interface UploadConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  result: SubmitResult | undefined;
  renderDuplicateItems: JSX.Element[];
  renderErrorItems: JSX.Element[];
  mode: keyof typeof uploadConfirmModalDescriptions;
}
