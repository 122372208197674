import { Box, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import React, { useMemo } from 'react';
import './Tabs.scss';
import { TabsProps } from './Tabs.types';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { getRoutePath, RoutePath, SIMILAR_CLAUSE_ID_PATH } from '../../../services/route.service';

export const Tabs: React.FC<TabsProps> = ({ base, components, idPath }) => {
  const { pathname } = useLocation();

  const { firstChildUrl, firstChildComponent, tabs } = useMemo(() => {
    const tabs = components.filter(({ disabled }) => !disabled);
    const { url: firstChildUrl, component: firstChildComponent } = tabs[0];
    return { firstChildComponent, firstChildUrl, tabs };
  }, [components]);

  const { value } = useMemo(() => {
    const paths = tabs.map((tab) => getRoutePath(base, tab.url));
    const value = paths.includes(pathname) ? pathname : getRoutePath(base, firstChildUrl);
    return { value };
  }, [base, firstChildUrl, pathname, tabs]);

  return (
    <div className='documents-tabs'>
      <TabContext value={value}>
        <Box sx={{ width: '100%' }}>
          <TabList>
            {tabs.map(({ label, url }) => (
              <Tab
                label={label}
                value={getRoutePath(base, url)}
                key={label}
                component={Link}
                to={url}
              />
            ))}
          </TabList>
        </Box>

        <div className='tab-panel'>
          <Routes>
            <Route index element={<Navigate to={firstChildUrl} replace />} />

            {tabs?.map(({ url, component }, index) => (
              <Route key={index} path={url} element={component} />
            ))}

            {idPath && <Route path={idPath} element={firstChildComponent} />}
            {idPath && (
              <Route
                path={getRoutePath(idPath, SIMILAR_CLAUSE_ID_PATH)}
                element={firstChildComponent}
              />
            )}
            <Route path='*' element={<Navigate to={RoutePath.NOT_FOUND} replace />} />
          </Routes>
        </div>
      </TabContext>
    </div>
  );
};
