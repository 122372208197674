import {
  useGetDraftClausesListWithParams,
  useGetPendingClausesListWithParams,
  useGetPendingDraftClausesListWithParams,
  useGetReportedClausesListWithParams,
  useGetUploadedClausesListWithParams,
} from '../../store/files/clauses/clauses.hooks';
import { RoutePath } from '../../services/route.service';
import {
  MenuItemsClauseReportedViewModal,
  MenuItemsClauseViewModalEdit,
  MenuItemsDrafts,
  MenuItemsReportedClauses,
  MenuItemsUploadedClauses,
} from '../Documents/DocumentContextMenu/DocumentContextMenu.helpers';
import { ContextMenuItem } from '../Documents/DocumentContextMenu/DocumentContextMenu.types';
import { useClauseWriteAccess, useDraftClauseWriteAccess } from '../../contexts/UserAccess';
import { ExportButtonProp } from '../Documents/Documents.types';

export interface UploadedClausesConfig extends ExportButtonProp {
  basePath: RoutePath;
  idPath?: RoutePath;
  menuOptions: ContextMenuItem[];
  title: string;
  useAccess: () => void;
  useGetDataListWithParams: typeof useGetUploadedClausesListWithParams;
  useGetPendingClausesListWithParams?: typeof useGetPendingClausesListWithParams;
  viewModalMenuItems: ContextMenuItem[];
}

export const UPLOADED_CLAUSES_CONFIG: UploadedClausesConfig = {
  basePath: RoutePath.UPLOADED_CLAUSES,
  menuOptions: MenuItemsUploadedClauses,
  title: 'Uploaded clauses',
  useAccess: useClauseWriteAccess,
  useGetDataListWithParams: useGetUploadedClausesListWithParams,
  useGetPendingClausesListWithParams: useGetPendingClausesListWithParams,
  viewModalMenuItems: MenuItemsClauseViewModalEdit,
};

export const REPORTED_CLAUSES_CONFIG: UploadedClausesConfig = {
  basePath: RoutePath.REPORTED_CLAUSES,
  menuOptions: MenuItemsReportedClauses,
  title: 'Reported clauses',
  useAccess: useClauseWriteAccess,
  useGetDataListWithParams: useGetReportedClausesListWithParams,
  viewModalMenuItems: MenuItemsClauseReportedViewModal,
};

export const SANDBOX_CLAUSES_CONFIG: UploadedClausesConfig = {
  basePath: RoutePath.SANDBOX_CLAUSES,
  exportButtonEnabled: true,
  idPath: RoutePath.ID,
  menuOptions: MenuItemsDrafts,
  title: 'Draft clauses',
  useAccess: useDraftClauseWriteAccess,
  useGetDataListWithParams: useGetDraftClausesListWithParams,
  useGetPendingClausesListWithParams: useGetPendingDraftClausesListWithParams,
  viewModalMenuItems: MenuItemsClauseReportedViewModal,
};
