import { useCallback } from 'react';

import { uiSelectors } from '../../../store/ui/ui.selectors';
import { useAppSelector } from '../../../store/hooks';
import { COMPARE_MODE } from '../../Clauses/ClauseCompareModal/CompareOptionsBar/CompareOptionsBar.types';
import { isDocumentResponse } from '../../../store/files/documentsAndClauses/list.helpers';

import DiffMatchPatch, { Diff } from 'diff-match-patch';
import './diff-match-patch-extended';
import { cloneDeep } from 'lodash';

const dmp = new DiffMatchPatch();

type DiffItem = {
  added?: boolean;
  removed?: boolean;
  unchanged?: boolean;
  similar?: boolean;
  value: string;
};

type Differences = { diffForView1: DiffItem[]; diffForView2: DiffItem[] };

export type Order = 0 | 1 | 2;

const removeWordBreaks = (value: string) => value.replaceAll('- ', '').trim();

export const detectSimilarWords = (diffArray: Diff[]): Diff[] => {
  const tmpArray = cloneDeep(diffArray);

  tmpArray.forEach(([key, value], index, array) => {
    const [nextKey, nextValue] = array[index + 1] || [];
    if (key === -1 && nextKey === 1) {
      if (removeWordBreaks(value) === removeWordBreaks(nextValue)) {
        array[index][0] = -2;
        array[index + 1][0] = 2;
      }
    }
  });

  return tmpArray;
};

const transformDiffs = (diffArray: Diff[]): Differences => {
  let diffForView1: DiffItem[] = [];
  let diffForView2: DiffItem[] = [];

  diffArray.forEach(([key, value]) => {
    const splitValues = value.split(' ');
    splitValues.forEach((splitValue) => {
      const item: DiffItem = { value: splitValue };

      if (key === -2) {
        // can be changed to item.similar for yellow highlight
        item.unchanged = true;
        diffForView1.push(item);
      } else if (key === -1) {
        item.removed = true;
        diffForView1.push(item);
      } else if (key === 0) {
        item.unchanged = true;
        diffForView1.push(item);
        diffForView2.push({ ...item });
      } else if (key === 1) {
        item.added = true;
        diffForView2.push(item);
      } else if (key === 2) {
        // can be changed to item.similar for yellow highlight
        item.unchanged = true;
        diffForView2.push(item);
      }
    });
  });

  diffForView1 = diffForView1.filter((item) => item.value.trim());
  diffForView2 = diffForView2.filter((item) => item.value.trim());

  return { diffForView1, diffForView2 };
};

export const extractTextFromHTML = (htmlString: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  let textContent = doc.body.textContent || '';

  textContent = textContent.trim().replace(/\s+/g, ' ');

  return textContent;
};

const trimEnd = (str: string) => {
  return str.replace(/[\s\uFEFF\xA0]+$/, '');
};

const matchAndHighlightPhrase = (
  text: string,
  phrase: string,
  skip?: boolean,
  similar?: boolean
) => {
  // // Escape all special regex characters in the phrase
  // we are doing it because we want to make regex treat special characters as normal ones and match them literally in the string
  const escapedPhrase = phrase.replace(/[-[\]{}()*+?.,\\^$|#\s<>]/g, '\\$&').trim();

  let regex;

  //   // Regex to match special characters and words anywhere in the text, avoiding matches inside HTML tags with some restrictions
  //   // regex match special characters with optional spaces around them
  //   // it can match with optional prefix and suffix non-word characters.
  //   // it should be the first place to look into if sth is broken or highilight is displayed partially
  // regex = new RegExp(
  //   `(?!<[^>]*|<mark>[^<]*)(\\s*${escapedPhrase}\\s*)(?![^<]*>|[^<]*</mark>)`,
  //   'gi'
  // );

  regex = new RegExp(`(?<!<[^>]*)\\s*${escapedPhrase}\\s*(?![^<]*>)`, 'gi');

  const match = regex.exec(text);
  if (match) {
    const beforeMatch = text.substring(0, match.index);

    const markedText = skip
      ? trimEnd(match[0])
      : similar
      ? `<mark class="similar">${match[0]}</mark>`
      : `<mark>${match[0]}</mark>`;
    const matchLength = skip ? trimEnd(match[0]).length : match[0].length;

    return {
      updatedHtml: beforeMatch + markedText,
      nextStartIndex: beforeMatch.length + matchLength,
    };
  }

  return { updatedHtml: '', nextStartIndex: 0 };
};

export const useDocumentCompare = (order?: Order) => {
  const { reference0, reference1, reference2 } = useAppSelector(
    uiSelectors.selectCompareDocumentReferences
  );

  const itemsInComparison =
    useAppSelector(uiSelectors.selectModalCompare)?.filter(isDocumentResponse).length ?? 0;
  const multipleComparison = itemsInComparison > 2;

  const compareMode = useAppSelector(uiSelectors.selectCompareMode);

  const invertedCompare =
    !multipleComparison && order === 0 && compareMode === COMPARE_MODE.SHOW_SIMILARITIES;

  const showDifferencesModeSelected = compareMode === COMPARE_MODE.SHOW_DIFFERENCES;
  const showSimilaitiesModeSelected = compareMode === COMPARE_MODE.SHOW_SIMILARITIES;

  const compareText = useCallback(() => {
    let differences = null;

    if (!multipleComparison) {
      //@ts-ignore
      differences = dmp.diff_wordMode(reference0, reference1);
    } else {
      //@ts-ignore
      differences = dmp.diff_wordMode(reference0, order === 1 ? reference1 : reference2);
    }

    return transformDiffs(detectSimilarWords(differences));
  }, [multipleComparison, order, reference0, reference1, reference2]);

  const applyDifferencesOnStringifiedHtml = useCallback(
    (html: string, order?: Order) => {
      // In text that we will potentially fetch, there are rare occurence of not decoded & sign (and potentially other sign too) and it manifest itself in html as &amp;
      // and text that we fetch from that html, have those decoded properly so there are some miss in matching fn since we try to match & to &amp;
      // so the algorithm will skip it as not recognized match or worse, it will match another occuerence of this sing witch might be corectly decoded
      // causing breakdown of continuity of the algorithm since I optimised it to cut from the initial text the searched chunk, because it will be slower and slower as algorithm would progress
      let result = html.replace(/&amp;/g, '&');
      let searchStartIndex = 0;
      let tmpResult = '';

      if (compareMode === COMPARE_MODE.SHOW_ORIGINAL) {
        return result;
      }
      const differences = compareText();

      if (order === 0 && differences.diffForView1.length) {
        if (multipleComparison) return result;

        differences.diffForView1.forEach(({ removed, value, unchanged, similar }) => {
          if (
            (showDifferencesModeSelected || showSimilaitiesModeSelected) &&
            (removed || unchanged || similar)
          ) {
            const { updatedHtml, nextStartIndex } = matchAndHighlightPhrase(
              result.substring(searchStartIndex),
              value,
              showDifferencesModeSelected ? unchanged : removed,
              similar
            );

            result = result.substring(searchStartIndex);
            tmpResult = tmpResult + updatedHtml;
            searchStartIndex = nextStartIndex;
          }
        });
      }

      if (order !== 0 && differences.diffForView2.length) {
        differences.diffForView2.forEach(({ added, value, unchanged, similar }) => {
          if (
            (showDifferencesModeSelected || showSimilaitiesModeSelected) &&
            (added || unchanged || similar)
          ) {
            const { updatedHtml, nextStartIndex } = matchAndHighlightPhrase(
              result.substring(searchStartIndex),
              value,
              showDifferencesModeSelected ? unchanged : added,
              similar
            );
            result = result.substring(searchStartIndex);
            tmpResult = tmpResult + updatedHtml;
            searchStartIndex = nextStartIndex;
          }
        });
      }

      return tmpResult.length ? tmpResult : result;
    },
    [
      compareMode,
      compareText,
      multipleComparison,
      showDifferencesModeSelected,
      showSimilaitiesModeSelected,
    ]
  );

  return { applyDifferencesOnStringifiedHtml, invertedCompare };
};
