import { UploadedFile, UploadedFileStatus } from './UploadedFileList.types';
import { listApi } from '../../store/files/upload/list.service';
import { getOriginalArgsPreLanding } from '../Documents/DocumentContextMenu/DocumentContextMenuDelete.helpers';
import { LIST_API_ENDPOINTS, LIST_API_TAGS } from '../../store/files/upload/list.service.types';

export const showDeleteAndEditButtons = (Status: string) =>
  Status !== UploadedFileStatus.DELETING &&
  Status !== UploadedFileStatus.UPLOAD_FAILED &&
  Status !== UploadedFileStatus.SUBMITTING;

export const getDocsByStatus = (compareToStatus: UploadedFileStatus, data?: UploadedFile[]) =>
  data?.filter(({ Status }) => Status === compareToStatus) || [];

export const showStatus = (DocumentId: string, Status: UploadedFileStatus) =>
  listApi.util.updateQueryData(
    LIST_API_ENDPOINTS.GET_LIST,
    getOriginalArgsPreLanding(LIST_API_TAGS.LIST, LIST_API_ENDPOINTS.GET_LIST),
    (documents) => {
      const document = documents.find((doc) => doc.DocumentId === DocumentId);
      if (document) {
        document.Status = Status;
      }
    }
  );
