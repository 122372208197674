import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Paper } from '@mui/material';
import './UploadClause.scss';
import { Controller, useForm } from 'react-hook-form';
import { requiredRule } from '../UploadFiles/MetadataForm/MetadataFormHelpers';
import {
  ClauseMetadata,
  ClauseMetadataFields,
  UploadClauseLocationState,
} from './UploadClause.types';
import { LoadingButton } from '@mui/lab';
import { MIN_CLAUSE_WORD_COUNT_MSG } from '../../config/config';
import { useLocation, useNavigate } from 'react-router-dom';
import { UploadSubmitClauses } from './UploadSubmitClauses/UploadSubmitClauses';
import {
  useCreateClauseMutation,
  useRecreateClauseMutation,
} from '../../store/files/clauses/clauses.list.service';
import { getFirstParamNameFromError, handleApiError } from '../../store/error.helpers';
import { useDocumentUploadClausesAccess, useUserAccessContext } from '../../contexts/UserAccess';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { documentsSelectors } from '../../store/files/documents/documents.selectors';
import { clearSelectedText } from '../../store/files/documents/documents.slice';
import { ClauseMetadataForm } from '../UploadFiles/MetadataForm/ClauseMetadataForm';
import { ClauseStatusIcon } from '../Clauses/ClauseStatusIcon/ClauseStatusIcon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomCheckbox } from '../UploadFiles/MetadataForm/ClauseMetadataForm.helpers';
import { hasMinimumWords, mapCreate, mapRecreate } from './UploadClause.helpers';
import useCustomTextFieldRenderer, { useDraftClausePrefill } from './UploadClause.hooks';

export const UploadClause = () => {
  const dispatch = useAppDispatch();
  useDocumentUploadClausesAccess();
  const [create, { isLoading }] = useCreateClauseMutation();
  const [recreate, { isLoading: isRecreateLoading }] = useRecreateClauseMutation();
  const [inputNameError, setInputNameError] = useState<string | undefined>(undefined);
  const [apiErrorMessage, setApiErrorMessage] = useState<string | undefined>(undefined);
  const [isError, setIsError] = useState(false);
  const [open, setOpen] = useState(false);
  const [localSelectedText, setLocalSelectedText] = useState('');
  const { hasReportsTabAccess } = useUserAccessContext();
  const isGPCUser = hasReportsTabAccess;

  const location = useLocation();
  const document = (location.state as UploadClauseLocationState)?.document;
  const clause = (location.state as UploadClauseLocationState)?.clause;
  const shouldPrefillForm = !!document || !!clause;
  const selectedTextFromStore: string = useAppSelector(documentsSelectors.selectSelectedText);

  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const {
    control,
    watch,
    trigger,
    formState: { isValid },
    setValue,
    getValues,
    getFieldState,
    reset,
  } = useForm<ClauseMetadata>({
    mode: 'onChange',
  });
  const [confirmed, setConfirmed] = useState(false);
  const adjustClauseMode = clause ? confirmed : true;

  const formMethods = {
    control,
    watch,
    trigger,
    setValue,
    getValues,
    getFieldState,
    reset,
  };

  const renderTextField = useCustomTextFieldRenderer(shouldPrefillForm);

  useEffect(() => {
    if (selectedTextFromStore && !localSelectedText) {
      setLocalSelectedText(selectedTextFromStore);
      dispatch(clearSelectedText());
    }
  }, [selectedTextFromStore, localSelectedText, dispatch]);

  const { draftMode, handleDraft } = useDraftClausePrefill(!shouldPrefillForm, reset);

  useEffect(() => {
    if (shouldPrefillForm && document) {
      setValue(ClauseMetadataFields.Entities, [document.Entity]);
      setValue(ClauseMetadataFields.Lops, [document.Lop]);
      setValue(ClauseMetadataFields.Lobs, [document.Lob]);
      setValue(ClauseMetadataFields.BusinessTypes, [document.BusinessType]);
      setValue(ClauseMetadataFields.Classification, document.Classification);
      setValue(ClauseMetadataFields.Language, document.Language);
      setValue(ClauseMetadataFields.Content, localSelectedText);
      setValue(ClauseMetadataFields.IsDraft, document.IsDraft);
    } else if (shouldPrefillForm && clause) {
      setValue(ClauseMetadataFields.Content, localSelectedText);
      setValue(ClauseMetadataFields.ClauseName, clause.ClauseName);
      setValue(ClauseMetadataFields.Entities, clause.Entities);
      setValue(ClauseMetadataFields.Lobs, clause.Lobs);
      setValue(ClauseMetadataFields.Lops, clause.Lops);
      setValue(ClauseMetadataFields.Countries, clause.Countries);
      setValue(ClauseMetadataFields.BusinessTypes, clause.BusinessTypes);
      setValue(ClauseMetadataFields.Classification, clause.Classification);
      setValue(ClauseMetadataFields.Language, clause.Language);
      setValue(ClauseMetadataFields.ClauseSources, clause.ClauseSources);
      setValue(ClauseMetadataFields.IndividualizationReason, clause.IndividualizationReason);
      setValue(ClauseMetadataFields.ClauseTypes, clause.ClauseTypes);
      setValue(ClauseMetadataFields.ClauseNumber, clause.ClauseNumber);
      setValue(ClauseMetadataFields.ClauseStatus, clause.ClauseStatus);
      setValue(ClauseMetadataFields.Remarks, clause.Remarks);
      trigger();
    } else if (draftMode) {
      handleDraft();
    } else {
      setValue(ClauseMetadataFields.Lops, []);
      setValue(ClauseMetadataFields.Lobs, []);
      setValue(ClauseMetadataFields.BusinessTypes, []);
      setValue(ClauseMetadataFields.Classification, '');
      setValue(ClauseMetadataFields.Language, '');
      setValue(ClauseMetadataFields.Content, '');
      setValue(ClauseMetadataFields.Entities, []);
      setValue(ClauseMetadataFields.ClauseTypes, []);
      setValue(ClauseMetadataFields.IsDraft, false);
      trigger();
    }
  }, [
    shouldPrefillForm,
    setValue,
    document,
    localSelectedText,
    trigger,
    clause,
    reset,
    draftMode,
    handleDraft,
  ]);

  const handleSubmit = async (e: FormEvent) => {
    const body = getValues();
    e?.preventDefault();
    setIsError(false);
    (clause
      ? recreate(mapRecreate(clause.ClauseId, body))
      : create(mapCreate(document?.DocumentId, body))
    )
      .unwrap()
      .catch((err) => {
        const paramName = getFirstParamNameFromError(err.response?.data);
        if (paramName) {
          setInputNameError(paramName);
        }
        setApiErrorMessage(handleApiError(err, true));
        setIsError(true);
      })
      .finally(() => {
        reset(body);
        trigger();
        setOpen(true);
      });
  };

  const { ClauseStatus } = getValues();

  return (
    <>
      <Paper elevation={0} className='upload-clause'>
        <div className='upload-clause___header'>
          {draftMode ? 'Draft clause upload' : 'Clause upload'}
        </div>
        <form className='metadata-form' onSubmit={handleSubmit}>
          <div className='upload-clause___wording'>
            <div className='upload-clause___title'>Clause wording</div>
            <div className='upload-clause___description'>Input the clause wording below</div>
            <Controller
              control={control}
              name={ClauseMetadataFields.Content}
              rules={{
                ...requiredRule(!shouldPrefillForm, true),
                validate: (value: string) =>
                  !hasMinimumWords(value) ? MIN_CLAUSE_WORD_COUNT_MSG : undefined,
              }}
              defaultValue=''
              render={({ field, fieldState }) => renderTextField({ field, fieldState })}
            />
          </div>
          <div className='upload-clause___info'>
            <ClauseStatusIcon clauseStatus={ClauseStatus} />
            <div className='upload-clause___title'>Clause info</div>
            <div className='upload-clause___description'>Fill in the clause information below</div>
            <ClauseMetadataForm
              formMethods={formMethods}
              apiErrorMessage={apiErrorMessage}
              inputNameError={inputNameError}
              baseDocumentForClauseCreation={document}
              disableTags
              adjustedClause={clause}
              draft={draftMode}
            />
            {shouldPrefillForm && isGPCUser && (
              <div className='form-warning upload-clause___update_clause_warning'>
                <InfoOutlinedIcon className='upload-clause___update_clause_warning-icon' />
                Warning: after submission of the clause, the update or removal will NOT be possible
                for Global P&C users.
              </div>
            )}
            {clause && (
              <div className='adjust-clause-warning'>
                <CustomCheckbox
                  checked={confirmed}
                  onChange={() => setConfirmed(!confirmed)}
                  label='I confirm that original clause is going to be permanently replaced with the new clause.'
                />
              </div>
            )}
            <div className='upload-clause___buttons'>
              <Button className='cancel-button' onClick={goBack}>
                Cancel
              </Button>
              <LoadingButton
                disabled={!isValid || !adjustClauseMode}
                variant='contained'
                size='large'
                type='submit'
                loading={isLoading || isRecreateLoading}
              >
                Upload
              </LoadingButton>
            </div>
          </div>
        </form>
      </Paper>
      <UploadSubmitClauses
        open={open}
        setOpen={setOpen}
        isError={isError}
        error={apiErrorMessage}
        draftMode={draftMode || (shouldPrefillForm && document?.IsDraft === true)}
      />
    </>
  );
};
