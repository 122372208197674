export const DELETE_MODAL_TITLE = (typeOfItem: string) => `Delete ${typeOfItem}`;
export const DELETE_MODAL_MESSAGE = (typeOfItem: string) =>
  `Are you sure you want to delete this ${typeOfItem}?`;
export const DELETE_MODAL_MESSAGE_MULTI = (numberOfDocs: number, typeOfItems: string) =>
  `Are you sure you want to delete ${numberOfDocs} ${typeOfItems}?`;
export const DELETE_MODAL_SUBMIT_BUTTON_LABEL = 'Delete';
export const DOCUMENT = 'document';
export const DOCUMENTS = 'documents';
export const CLAUSE = 'clause';
export const CLAUSES = 'clauses';
export const REPORT = 'report';
export const REPORTS = 'reports';
export const SAVED_SEARCH = 'saved search';
export const SAVED_SEARCHES = 'saved searches';

export const REMOVE_FROM_BOOKMARK_MODAL_TITLE = 'Remove file';
export const REMOVE_FROM_BOOKMARK_MODAL_MESSAGE =
  'Are you sure you want to remove this file from bookmark?';
export const REMOVE_FROM_BOOKMARK_MODAL_SUBMIT_BUTTON_LABEL = 'Remove';

export const SUBMIT_UPLOAD_MODAL_ANONYMIZATION_TITLE = 'Submit upload';
export const SUBMIT_UPLOAD_MODAL_ANONYMIZATION_MESSAGE = (numberOfDocs: number) =>
  `You are trying to submit ${numberOfDocs} documents. By clicking continue you confirm that these documents don't contain personal data`;
export const SUBMIT_UPLOAD_MODAL_SUBMIT_BUTTON_LABEL = 'Submit';

export const BULK_EDIT_MODAL_TITLE = 'Bulk metadata edit';
export const BULK_EDIT_MODAL_MESSAGE = `You are trying to edit metadata of multiple documents. Please confirm that you want to proceed`;
export const BULK_EDIT_MODAL_SUBMIT_BUTTON_LABEL = 'OK';

export const LEAVE_PAGE_MODAL_TITLE = 'Warning';
export const LEAVE_PAGE_MODAL_MESSAGE_UNSAVED_DATA = `The data is not saved. Do you want to exit and lose the changes?`;
export const LEAVE_PAGE_MODAL_MESSAGE_UPLOAD_IN_PROGRESS = `Upload in progress. Do you want to exit and interrupt?`;
export const LEAVE_PAGE_MODAL_MESSAGE_SUBMIT_IN_PROGRESS = `Submit in progress. Do you want to exit and interrupt?`;
export const LEAVE_PAGE_MODAL_SUBMIT_BUTTON_LABEL = 'OK';

export const DELETE_MESSAGE_PARENT_RELATED_DOCUMENT =
  'There is at least one document or clause related to a given file \n in the GWR.';

export const CLOSE_CHAT_MODAL_TITLE = 'Close conversation';
export const CLOSE_CHAT_MODAL_MESSAGE =
  'Are you sure you want to close a chat? The history of this conversation will not be saved.';
export const CLOSE_CHAT_MODAL_CANCEL_BUTTON_LABEL = 'Return';
export const CLOSE_CHAT_MODAL_SUBMIT_BUTTON_LABEL = 'Close';
