import { matchPath, useLocation, useParams } from 'react-router-dom';
import { useGetDocumentsListQuery } from '../../../store/files/documents/documents.list.service';
import {
  DocumentsListParams,
  DocumentStatus,
} from '../../../store/files/documents/documents.list.types';
import { openDocumentViewModal } from '../../../store/files/documents/documents.slice';
import { useAppDispatch } from '../../../store/hooks';
import { showLoader, showModal } from '../../../store/ui/ui.slice';
import { DOCUMENT_NOT_EXIST_TITLE } from '../../DocumentBar/DocumentBarList';
import {
  SANDBOX_DOCUMENTS_ID_PATH,
  SANDBOX_DOCUMENTS_ID_SIMILAR_CLAUSE_ID_PATH,
} from '../../../services/route.service';
import { formatBoolean } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { useEffect, useMemo } from 'react';
import { getDocumentPath } from './DocumentRelatedClauses/ClauseRelatedDocuments.hooks';

const DOCUMENT_NOT_EXIST_MSG =
  'The Document is not available anymore in Global Wording Repository.';

const getErrorModalPayload = (isDraft: boolean) => ({
  title: DOCUMENT_NOT_EXIST_TITLE,
  reason: DOCUMENT_NOT_EXIST_MSG,
  closeAction: getDocumentPath(isDraft),
});

export const useFetchAndDispatchDocument = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { id } = useParams();

  const isDraft = useMemo(
    () =>
      !!matchPath(SANDBOX_DOCUMENTS_ID_PATH, pathname) ||
      !!matchPath(SANDBOX_DOCUMENTS_ID_SIMILAR_CLAUSE_ID_PATH, pathname),
    [pathname]
  );

  const queryParameters: DocumentsListParams = {
    DocumentId: [id ?? ''],
    IsDraft: formatBoolean(isDraft),
    Status: [DocumentStatus.READY],
  };
  const { data: documents, isLoading } = useGetDocumentsListQuery(queryParameters, { skip: !id });

  useEffect(() => {
    setTimeout(() => {
      dispatch(showLoader(isLoading));
    });
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (id && documents) {
      const document = documents.Documents.find(({ DocumentId }) => DocumentId === id);
      if (document) {
        dispatch(openDocumentViewModal({ document }));
      } else {
        dispatch(showModal(getErrorModalPayload(isDraft)));
      }
    }
  }, [dispatch, documents, id, isDraft]);
};
