import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import uploadReducer from './files/upload/upload.slice';
import { listApi } from './files/upload/list.service';
import documentsReducer from './files/documents/documents.slice';
import clausesReducer from './files/clauses/clauses.slice';
import bookmarksReducer from './files/bookmarks/bookmarks.slice';
import adminPanelReducer from './adminPanel/adminPanel.slice';
import { documentsListApi } from './files/documents/documents.list.service';
import { bookmarksApi } from './files/bookmarks/bookmarks.service';
import { reportsApi } from './files/reports/reports.service';
import { commentsApi } from './files/comments/comments.service';
import uiReducer from './ui/ui.slice';
import { savedSearchApi } from './user/savedsearch.service';
import savedSearchReducer from './user/savedsearch.slice';
import { clausesListApi } from './files/clauses/clauses.list.service';
import { adminPanelApi } from './adminPanel/adminPanel.service';
import { authorizationApi } from '../contexts/authorizarion.service';
import { tagsApi } from './files/tags/tags.service';
import { documentChatSlice } from './files/chat/documentChat.slice';

const rootReducer = combineReducers({
  files: combineReducers({
    upload: uploadReducer,
    documents: documentsReducer,
    clauses: clausesReducer,
    bookmarks: bookmarksReducer,
  }),
  [listApi.reducerPath]: listApi.reducer,
  [documentsListApi.reducerPath]: documentsListApi.reducer,
  [clausesListApi.reducerPath]: clausesListApi.reducer,
  [savedSearchApi.reducerPath]: savedSearchApi.reducer,
  [bookmarksApi.reducerPath]: bookmarksApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [adminPanelApi.reducerPath]: adminPanelApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  ui: uiReducer,
  savedSearch: savedSearchReducer,
  adminPanel: adminPanelReducer,
  authorizationApi: authorizationApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [documentChatSlice.name]: documentChatSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      listApi.middleware,
      documentsListApi.middleware,
      clausesListApi.middleware,
      savedSearchApi.middleware,
      bookmarksApi.middleware,
      reportsApi.middleware,
      adminPanelApi.middleware,
      commentsApi.middleware,
      authorizationApi.middleware,
      tagsApi.middleware
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
