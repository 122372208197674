import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
import './SwitchWithLabel.scss';
import React from 'react';
import { BOOLEAN_OPTIONS, BOOLEAN_VALUES } from '../UploadFiles/MetadataForm/MetadataFormHelpers';

export interface RadioGroupSwitchProps {
  onChange: (value: string) => void;
  value?: string | undefined;
}

export const RadioGroupSwitch: React.FC<RadioGroupSwitchProps> = React.forwardRef(
  ({ value, onChange, ...props }, ref) => {
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    };

    return (
      <div className='radio-group-with-label'>
        <RadioGroup ref={ref} row value={value} onChange={handleRadioChange} {...props}>
          <FormControlLabel
            value={BOOLEAN_VALUES.TRUE}
            control={<Radio />}
            label={BOOLEAN_OPTIONS.YES}
          />
          <FormControlLabel
            value={BOOLEAN_VALUES.FALSE}
            control={<Radio />}
            label={BOOLEAN_OPTIONS.NO}
          />
        </RadioGroup>
      </div>
    );
  }
);
