import {
  useDocumentReportedAccess,
  useDocumentWriteAccess,
  useDraftDocumentWriteAccess,
} from '../../contexts/UserAccess';
import {
  useGetDraftDocumentsListWithParams,
  useGetPendingDocumentsListWithParams,
  useGetPendingDraftDocumentsListWithParams,
  useGetReportedDocumentsListWithParams,
  useGetUploadedDocumentsListWithParams,
} from '../../store/files/documents/documents.hooks';
import { RoutePath } from '../../services/route.service';
import {
  MenuItemsDrafts,
  MenuItemsReportedDocuments,
  MenuItemsReportedReadDocuments,
  MenuItemsUploadedDocuments,
} from '../Documents/DocumentContextMenu/DocumentContextMenu.helpers';
import { ContextMenuItem } from '../Documents/DocumentContextMenu/DocumentContextMenu.types';
import { ExportButtonProp } from '../Documents/Documents.types';

export interface UploadedDocumentsConfig extends ExportButtonProp {
  basePath: RoutePath;
  disableBulkDelete?: boolean;
  disableSpreadsheet?: boolean;
  hideBulk?: boolean;
  idPath?: RoutePath;
  menuOptions: ContextMenuItem[];
  title: string;
  useAccess: () => void;
  useGetDataListWithParams: typeof useGetUploadedDocumentsListWithParams;
  useGetPendingDocumentsListWithParams?: typeof useGetPendingDocumentsListWithParams;
}

export const UPLOADED_DOCS_CONFIG: UploadedDocumentsConfig = {
  basePath: RoutePath.UPLOADED_DOCUMENTS,
  menuOptions: MenuItemsUploadedDocuments,
  title: 'Uploaded documents',
  useAccess: useDocumentWriteAccess,
  useGetDataListWithParams: useGetUploadedDocumentsListWithParams,
  useGetPendingDocumentsListWithParams: useGetPendingDocumentsListWithParams,
};

export const REPORTED_DOCS_CONFIG: UploadedDocumentsConfig = {
  basePath: RoutePath.REPORTED_DOCUMENTS,
  menuOptions: MenuItemsReportedDocuments,
  title: 'Reported documents',
  useAccess: useDocumentReportedAccess,
  useGetDataListWithParams: useGetReportedDocumentsListWithParams,
};

export const REPORTED_READ_DOCS_CONFIG: UploadedDocumentsConfig = {
  basePath: RoutePath.REPORTED_DOCUMENTS,
  disableBulkDelete: true,
  disableSpreadsheet: true,
  hideBulk: true,
  menuOptions: MenuItemsReportedReadDocuments,
  title: 'Reported documents',
  useAccess: useDocumentReportedAccess,
  useGetDataListWithParams: useGetReportedDocumentsListWithParams,
};

export const SANDBOX_DOCS_CONFIG: UploadedDocumentsConfig = {
  basePath: RoutePath.SANDBOX_DOCUMENTS,
  disableSpreadsheet: true,
  exportButtonEnabled: true,
  hideBulk: true,
  idPath: RoutePath.ID,
  menuOptions: MenuItemsDrafts,
  title: 'Draft documents',
  useAccess: useDraftDocumentWriteAccess,
  useGetDataListWithParams: useGetDraftDocumentsListWithParams,
  useGetPendingDocumentsListWithParams: useGetPendingDraftDocumentsListWithParams,
};
