import {
  DOCUMENT_ID_PATH,
  DOCUMENT_ID_SIMILAR_CLAUSE_ID_PATH,
  getRoutePath,
  RoutePath,
  SANDBOX_DOCUMENTS_ID_PATH,
  SANDBOX_DOCUMENTS_ID_SIMILAR_CLAUSE_ID_PATH,
} from '../../services/route.service';
import { getFilteredPages } from '../../store/ui/ui.helpers';

const documentBarAllowedPages = [
  RoutePath.DOCUMENTS,
  DOCUMENT_ID_PATH,
  DOCUMENT_ID_SIMILAR_CLAUSE_ID_PATH,
  SANDBOX_DOCUMENTS_ID_PATH,
  SANDBOX_DOCUMENTS_ID_SIMILAR_CLAUSE_ID_PATH,
  getRoutePath(RoutePath.SANDBOX_DOCUMENTS, RoutePath.UPLOADED_DOCUMENTS_LIST_VIEW),
  getRoutePath(RoutePath.UPLOADED_DOCUMENTS, RoutePath.UPLOADED_DOCUMENTS_LIST_VIEW),
  getRoutePath(RoutePath.REPORTED_DOCUMENTS, RoutePath.UPLOADED_DOCUMENTS_LIST_VIEW),
  getRoutePath(
    getRoutePath(RoutePath.BOOKMARKS, RoutePath.BOOKMARK_ID) as RoutePath,
    RoutePath.BOOKMARKS_DOCUMENTS
  ),
];
export const shouldShowSelectedBar = (pathname: string, minimizedDocsLength: number | undefined) =>
  getFilteredPages(documentBarAllowedPages, pathname) && !!minimizedDocsLength;
