import axiosAuth from '../../../components/Auth/axiosAuth';
import { DOCUMENT_CHAT_ENDPOINT } from '../../../services/api.service.config';
import { AxiosResponse } from 'axios';
import {
  DocumentChatDeleteRequest,
  DocumentChatRequest,
  DocumentChatResponse,
} from './documentChat.types';

class DocumentChatService {
  askQuestion(payload: DocumentChatRequest) {
    return axiosAuth.post<DocumentChatRequest, AxiosResponse<DocumentChatResponse>>(
      DOCUMENT_CHAT_ENDPOINT,
      payload
    );
  }

  deleteSession(payload: DocumentChatDeleteRequest) {
    return axiosAuth.delete<DocumentChatDeleteRequest, AxiosResponse<void>>(
      DOCUMENT_CHAT_ENDPOINT,
      { data: payload }
    );
  }
}

const documentChatService = new DocumentChatService();

export default documentChatService;
