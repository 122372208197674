import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uploadSelectors } from '../../../store/files/upload/upload.selectors';
import { closeModal, setIsPdfRendered } from '../../../store/files/upload/upload.slice';
import { useUpdateMetadataMutation } from '../../../store/files/upload/list.service';
import { UPDATE_METADATA_CACHE_KEY } from '../../../store/files/upload/list.service.types';
import { handleApiError } from '../../../store/error.helpers';
import {
  BOOLEAN_VALUES,
  cleanupLocalFields,
  formatApiDate,
} from '../MetadataForm/MetadataFormHelpers';
import { DocumentMetadata, DocumentMetadataFields } from '../MetadataForm/MetadataForm.types';
import { MetadataEditModalModes, SCANNING_RESULT_CLEAN } from './MetadataEditModal.types';
import { useGetUploadedFiles } from '../useGetUploadedFiles';
import { UseFormSetValue } from 'react-hook-form';
import { CLASSIFICATION_C2 } from '../MetadataForm/getDraftPrefilledClause';

export const DRAFT = 'draft';

export const useMetadataEditModal = (addNewVersionMode: boolean) => {
  const dispatch = useAppDispatch();
  const documentId = useAppSelector(uploadSelectors.selectModalDocument);
  const [apiError, setApiError] = useState<string>();
  const [pdfViewerId, setPdfViewerId] = useState<string>();
  const [step, setStep] = useState<MetadataEditModalModes>();
  const isFileAccepted = useAppSelector(uploadSelectors.selectIsFileAccepted);
  const uploadingDocId = useAppSelector(uploadSelectors.selectUploadingDocId);
  const previousVersionDocument = useAppSelector(uploadSelectors.selectPreviousVersionDocument);

  const skipQuery = addNewVersionMode ? !uploadingDocId : !documentId;
  const { docs } = useGetUploadedFiles({
    skipQuery,
    overrideIsDraftByUploadButton: addNewVersionMode,
    overrideValue: false,
  });
  const tempUploadingDoc = !!docs.length && isFileAccepted ? docs[0] : null;
  const uploadingDoc = uploadingDocId
    ? docs.find((doc) => doc.DocumentId === uploadingDocId)
    : tempUploadingDoc;

  useEffect(() => {
    if (addNewVersionMode) {
      dispatch(setIsPdfRendered(false));
      setStep(MetadataEditModalModes.UPLOAD);
    } else {
      setPdfViewerId(documentId);
      setStep(MetadataEditModalModes.VIEWER);
    }
  }, [addNewVersionMode, documentId, dispatch]);

  useEffect(() => {
    if (isFileAccepted) {
      setStep(MetadataEditModalModes.PROCESSING);
    }
  }, [isFileAccepted]);

  useEffect(() => {
    if (
      step === MetadataEditModalModes.PROCESSING &&
      uploadingDoc?.ScanningResult === SCANNING_RESULT_CLEAN
    ) {
      setPdfViewerId(uploadingDocId);
      setStep(MetadataEditModalModes.VIEWER);
    }
  }, [uploadingDocId, uploadingDoc, step]);

  const document = addNewVersionMode
    ? docs?.find((d) => d.DocumentId === uploadingDocId)
    : docs?.find((d) => d.DocumentId === documentId);

  const [update, { isLoading }] = useUpdateMetadataMutation({
    fixedCacheKey: UPDATE_METADATA_CACHE_KEY,
  });

  const handleClose = () => {
    setApiError(undefined);
    dispatch(closeModal());
  };

  const handleSubmit = (data: DocumentMetadata) => {
    setApiError(undefined);
    if (!document) return;
    update(cleanupLocalFields([{ ...document, ...data }]))
      .unwrap()
      .then(() => {
        if (!addNewVersionMode) {
          handleClose();
        }
      })
      .catch((response) => setApiError(handleApiError(response)));
  };

  return {
    documentId,
    uploadingDoc,
    apiError,
    pdfViewerId,
    step,
    document,
    isLoading,
    handleClose,
    handleSubmit,
    previousVersionDocument,
  };
};

export const useDraftDocumentPrefill = (
  setValue: UseFormSetValue<DocumentMetadata>,
  document?: DocumentMetadata
) => {
  const handleDraftDocumentPrefill = useCallback(() => {
    const currentDate = formatApiDate(new Date());
    if (!document?.ProductType)
      setValue(DocumentMetadataFields.ProductType, DRAFT, { shouldValidate: true });
    if (!document?.InsurerIndustry)
      setValue(DocumentMetadataFields.InsurerIndustry, DRAFT, { shouldValidate: true });
    if (!document?.Classification)
      setValue(DocumentMetadataFields.Classification, CLASSIFICATION_C2, { shouldValidate: true });
    if (!document?.ValidFrom)
      setValue(DocumentMetadataFields.ValidFrom, currentDate, { shouldValidate: true });
    if (!document?.LatestVersion)
      setValue(DocumentMetadataFields.LatestVersion, BOOLEAN_VALUES.FALSE, {
        shouldValidate: true,
      });
    if (!document?.Active)
      setValue(DocumentMetadataFields.Active, BOOLEAN_VALUES.FALSE, { shouldValidate: true });
  }, [setValue, document]);

  return { handleDraftDocumentPrefill };
};
