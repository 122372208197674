import { openNewClauseViewModal, openNewDocumentViewModal } from './ClauseRelatedDocuments.hooks';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  isClauseResponse,
  isDocumentResponse,
} from '../../../../store/files/documentsAndClauses/list.helpers';
import { ItemProps } from './OpenItem.types';

export const OpenItem = ({ item }: ItemProps) => {
  const isDocument = isDocumentResponse(item);
  const isClause = isClauseResponse(item);

  const handleClick = () => {
    if (isDocument) {
      openNewDocumentViewModal(item);
    } else if (isClause) {
      openNewClauseViewModal(item);
    }
  };

  return (
    <IconButton onClick={handleClick}>
      <OpenInNewIcon />
    </IconButton>
  );
};
