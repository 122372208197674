import { TEST_ID } from '../../../config/test-fields-ids.config';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ModalCloseButtonProps } from './ModalCloseButton.types';
import './ModalCloseButton.scss';

export const ModalCloseButton = ({ onClose, tooltip }: ModalCloseButtonProps) => {
  return (
    <Tooltip title={tooltip ?? ''}>
      <IconButton
        className='modal-close-button'
        data-test-id={TEST_ID.DOC_VIEW.CLOSE_DOC}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );
};
