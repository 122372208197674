import { useParams } from 'react-router-dom';
import { useGetSimilarQuery } from '../../../store/files/documents/documents.list.service';

export const useSimilarHighlightsHook = (documentId: string = '') => {
  const { clause_id } = useParams();
  const { data: similarData } = useGetSimilarQuery(clause_id ?? '', {
    skip: !documentId || !clause_id,
  });
  return similarData?.Highlights[documentId];
};
