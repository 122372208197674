import React, { Dispatch, FormEvent, useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { ButtonWithIcon } from '../../StaticComponents/ButtonWithIcon';
import {
  AdminPanelUser,
  GroupMetadata,
  UpdateUserGroups,
  UsersGroupsOperation,
} from '../../../store/adminPanel/adminPanel.types';
import './EditUsersGroupsModal.scss';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from 'react-hook-form';
import {
  useGetMetadataQuery,
  useGetUsersQuery,
  useUpdateUserGroupsMutation,
} from '../../../store/adminPanel/adminPanel.service';
import { DocumentFilter } from '../../Documents/DocumentsSearch/DocumentFilter';
import { GroupNameFilter } from '../GroupNameFilter/GroupNameFilter';
import { filterUserGroupsValues, sortUsersGroups } from '../AdminPanel.helpers';
import { handleApiError } from '../../../store/error.helpers';
import { useApiError } from '../../../store/ui/ui.helpers';
import { BulkEditAttention } from './BulkEditAttention';
import { UsersListChip } from './UsersListChip';
import { useSnackbar } from 'notistack';
import { ApiError } from '../../UploadFiles/MetadataForm/ApiError';
import { Messages } from '../../../services/messages.service.types';

export const BulkEditGroupsModal: React.FC<{
  onClose: Dispatch<void>;
  selectedUsers: string[];
  setSelectedUsers: Dispatch<string[]>;
  operation: UsersGroupsOperation;
}> = ({ operation, onClose, selectedUsers, setSelectedUsers }) => {
  const { apiError, setApiError, clearApiError } = useApiError();
  const [showAttention, setShowAttention] = useState<boolean>(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState<AdminPanelUser[] | undefined>([]);

  const { data: users } = useGetUsersQuery();
  const [update, { isLoading: isUpdatingUserGroups }] = useUpdateUserGroupsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { data: metadata } = useGetMetadataQuery();

  const { control, watch, setValue, getValues, reset } = useForm<AdminPanelUser>();
  const selectedEntity = watch('Entity');
  const selectedGroups = watch('UserGroups');

  useEffect(() => {
    if (selectedUsers) {
      setSelectedUserInfo(users?.filter(({ Email }) => selectedUsers?.includes(Email)));
    }
  }, [users, selectedUsers, setSelectedUserInfo]);

  useEffect(() => {
    if (selectedUsers)
      reset({
        Entity: '',
        UserGroups: [],
      });
  }, [selectedUsers, reset]);

  const filteredUserGroupsValues = filterUserGroupsValues(
    selectedEntity,
    selectedGroups,
    sortUsersGroups(metadata?.Groups),
    setValue
  );

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    if (!showAttention) {
      setShowAttention(true);
    }
    if (showAttention) {
      const updateUserGroups: UpdateUserGroups = {
        Groups: selectedGroups,
        UserEmails: selectedUsers ?? [],
        Operation: operation,
      };
      update(updateUserGroups)
        .unwrap()
        .then(() => {
          setSelectedUsers([]);
          enqueueSnackbar(Messages.AdminPanelBulkEditGroupsModal);
        })
        .catch((response) => setApiError(handleApiError(response, true)));
      setShowAttention(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={!!operation}>
      <form className='edit-users-group-form__edit-user__form' onSubmit={handleSubmit}>
        <div
          className='edit-users-groups-modal'
          data-test-id={TEST_ID.ADMIN_PANEL_PAGE.ADMIN_PANEL_BULK_EDIT_USER_GROUPS_MODAL}
        >
          <ModalClose onClose={handleClose} />
          <div className='confirmation-modal-header'>
            <div className='confirmation-modal-title'>Bulk {operation} user’s group</div>
            <div className='confirmation-modal-message'>
              {showAttention ? (
                <BulkEditAttention
                  selectedUsers={selectedUserInfo}
                  userGroups={getValues('UserGroups')}
                  operation={operation}
                />
              ) : (
                <div className='edit-users-group-form__edit-user'>
                  <UsersListChip selectedUsers={selectedUserInfo} />
                  <div className='edit-users-group-form__edit-user__filters'>
                    <Controller
                      control={control}
                      name='Entity'
                      render={({ field }) => (
                        <DocumentFilter
                          {...field}
                          label='Entity'
                          values={metadata?.Entities}
                          firstParam={null}
                          onChange={(value) => {
                            field.onChange(value);
                            clearApiError();
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name='UserGroups'
                      render={({ field, fieldState: { error } }) => (
                        <GroupNameFilter
                          {...field}
                          error={error}
                          multiple
                          label='User group(s)'
                          values={filteredUserGroupsValues as unknown as GroupMetadata[]}
                          onChange={(value) => {
                            field.onChange(value);
                            clearApiError();
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className='edit-users-group-form__edit-user__form__error'>
                    <ApiError apiError={apiError} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='modal-buttons'>
            <ButtonWithIcon
              className='cancel-button'
              onClick={handleClose}
              disabled={isUpdatingUserGroups}
            >
              Cancel
            </ButtonWithIcon>
            <LoadingButton
              className='confirm-button'
              loading={isUpdatingUserGroups}
              type='submit'
              disabled={!selectedGroups?.length}
              onClick={handleSubmit}
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </form>
    </Modal>
  );
};
