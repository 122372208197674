import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { listApi, useDeleteFileMutation } from '../../../store/files/upload/list.service';
import { useAppDispatch } from '../../../store/hooks';
import {
  UPLOADED_FILE_TYPE,
  UploadedFileDeleteButtonProps,
} from './UploadedFileDeleteButton.types';
import { useConfirmationModal } from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.hook';
import { DeleteConfirmModal } from '../../StaticComponents/Modals/ConfirmationModal/DeleteConfirmModal';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { LIST_API_TAGS } from '../../../store/files/upload/list.service.types';
import { ErrorMessages } from '../../../services/errors.service.types';
import { useSnackbar } from 'notistack';
import { UploadedFileStatus } from '../UploadedFileList.types';
import { showStatus } from '../UploadedFileList.helper';
import {
  CLAUSE,
  DELETE_MODAL_MESSAGE,
  DELETE_MODAL_TITLE,
  DOCUMENT,
} from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';
import {
  clausesListApi,
  useDeleteClauseMutation,
} from '../../../store/files/clauses/clauses.list.service';
import { showLoader } from '../../../store/ui/ui.slice';
import { timeoutPromise } from '../../../helpers/app.helpers';
import { REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION } from '../../../config/config';
import { CLAUSES_LIST_API_TAGS } from '../../../store/files/clauses/clauses.list.types';
import {
  documentsListApi,
  useDeleteDocumentMutation,
} from '../../../store/files/documents/documents.list.service';
import { DOCUMENTS_LIST_API_TAGS } from '../../../store/files/documents/documents.list.types';
import { useDeleteTagMutation } from '../../../store/files/tags/tags.service';

const usePreLandingDocumentDelete = (id: string) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteFunction] = useDeleteFileMutation();

  return async () => {
    dispatch(showStatus(id, UploadedFileStatus.DELETING));

    deleteFunction(id)
      .unwrap()
      .catch(() => enqueueSnackbar(ErrorMessages.FileDeleteError, { variant: 'error' }))
      .then(() => {
        dispatch(listApi.util.invalidateTags([LIST_API_TAGS.LIST]));
      });
  };
};

const useDocumentDelete = (id: string) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteFunction] = useDeleteDocumentMutation();

  return async () => {
    deleteFunction(id)
      .unwrap()
      .catch(() => enqueueSnackbar(ErrorMessages.FileDeleteError, { variant: 'error' }))
      .then(async () => {
        dispatch(showLoader(true));
        await timeoutPromise(REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION);
        dispatch(documentsListApi.util.invalidateTags([DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST]));
        dispatch(showLoader(false));
      });
  };
};

const useClauseDelete = (id: string) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteFunction] = useDeleteClauseMutation();

  return async () => {
    try {
      dispatch(showLoader(true));
      await deleteFunction(id).unwrap();
      await timeoutPromise(REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION);
      dispatch(clausesListApi.util.invalidateTags([CLAUSES_LIST_API_TAGS.CLAUSES_LIST]));
    } catch (error) {
      enqueueSnackbar(ErrorMessages.FileDeleteError, { variant: 'error' });
    } finally {
      dispatch(showLoader(false));
    }
  };
};

const useTagDelete = (id: string) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteFunction] = useDeleteTagMutation();

  return async () => {
    try {
      dispatch(showLoader(true));
      await deleteFunction(id).unwrap();
      enqueueSnackbar('Tag has been deleted successfully.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(ErrorMessages.TagDeleteError, { variant: 'error' });
    } finally {
      dispatch(showLoader(false));
    }
  };
};

const mapping: Record<
  UPLOADED_FILE_TYPE,
  { title: string; message: string; useDelete: (id: string) => () => Promise<void> }
> = {
  [UPLOADED_FILE_TYPE.PRE_LANDING_DOC]: {
    title: DELETE_MODAL_TITLE(DOCUMENT),
    message: DELETE_MODAL_MESSAGE(DOCUMENT),
    useDelete: usePreLandingDocumentDelete,
  },
  [UPLOADED_FILE_TYPE.PENDING_DOC]: {
    title: DELETE_MODAL_TITLE(DOCUMENT),
    message: DELETE_MODAL_MESSAGE(DOCUMENT),
    useDelete: useDocumentDelete,
  },
  [UPLOADED_FILE_TYPE.PENDING_CLAUSE]: {
    title: DELETE_MODAL_TITLE(CLAUSE),
    message: DELETE_MODAL_MESSAGE(CLAUSE),
    useDelete: useClauseDelete,
  },
  [UPLOADED_FILE_TYPE.TAG]: {
    title: DELETE_MODAL_TITLE('tag'),
    message: DELETE_MODAL_MESSAGE('tag'),
    useDelete: useTagDelete,
  },
};

export const UploadedFileDeleteButton = ({
  FileId,
  type,
  disabled = false,
}: UploadedFileDeleteButtonProps) => {
  const { open, handleOpen, handleClose } = useConfirmationModal();

  const { title, message, useDelete } = mapping[type];
  const handleDelete = useDelete(FileId);

  return (
    <>
      <IconButton onClick={handleOpen} disabled={disabled}>
        <DeleteOutlineOutlinedIcon
          data-test-id={TEST_ID.UPLOAD_FILES.DELETE_UPLOADED_DOCUMENT}
          fontSize='small'
        />
      </IconButton>

      <DeleteConfirmModal
        open={open}
        onClose={handleClose()}
        onConfirm={handleDelete}
        title={title}
        message={message}
      />
    </>
  );
};
