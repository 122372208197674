import React, { useEffect, useState } from 'react';
import './DocumentBarList.scss';
import { useAppDispatch } from '../../store/hooks';
import { showModal } from '../../store/ui/ui.slice';
import { MINIMIZED_DATA, MinimizedData } from '../../store/ui/ui.types';
import { useGetDocumentsListQuery } from '../../store/files/documents/documents.list.service';
import {
  clearSelectedText,
  openDocumentViewModal,
} from '../../store/files/documents/documents.slice';
import { closeMinimizedDoc } from '../../store/ui/ui.actions';
import { BarList } from './BarList';
import { useIndexOfCompare } from '../../store/ui/ui.helpers';
import { DocumentBarListProps } from './DocumentBarList.types';
import { DocumentsListParams } from '../../store/files/documents/documents.list.types';

const DOCUMENT_NOT_EXIST_MSG =
  'The Document you have selected is not available anymore in Global Wording Repository.';
export const DOCUMENT_NOT_EXIST_TITLE = 'Document no longer exists';

export const DocumentBarList: React.FC<DocumentBarListProps> = ({
  minimizedData,
  itemsToCompare,
}) => {
  const [clickedDocumentId, setClickedDocumentId] = useState<string>('');
  const [getDocument, setGetDocument] = useState(false);
  const dispatch = useAppDispatch();
  const queryParameters: DocumentsListParams = { DocumentId: [clickedDocumentId] };
  const {
    data: documents,
    isSuccess,
    isFetching,
    isError,
  } = useGetDocumentsListQuery(queryParameters, {
    skip: !getDocument,
  });

  const handleClose = (dataId: MinimizedData['dataId']) => (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    dispatch(closeMinimizedDoc([dataId]));
  };

  const handleClick = (dataId: MinimizedData['dataId']) => () => {
    setClickedDocumentId(dataId);
    setGetDocument(true);
    if (clickedDocumentId !== dataId) {
      dispatch(clearSelectedText());
    }
  };

  useEffect(() => {
    if (getDocument) {
      const document = documents?.Documents?.[0];
      const documentExists = !isFetching && isSuccess && document;
      const documentNotExists = !isFetching && !isError && !document;
      if (documentExists) {
        setGetDocument(false);
        dispatch(openDocumentViewModal({ document: document }));
      } else if (documentNotExists) {
        setGetDocument(false);
        dispatch(showModal({ title: DOCUMENT_NOT_EXIST_TITLE, reason: DOCUMENT_NOT_EXIST_MSG }));
        dispatch(closeMinimizedDoc([clickedDocumentId]));
      }
    }
  }, [
    isSuccess,
    isFetching,
    isError,
    clickedDocumentId,
    dispatch,
    documents?.Documents,
    getDocument,
  ]);

  const indexOfCompare = useIndexOfCompare(itemsToCompare);

  return (
    <BarList
      minimizedData={minimizedData}
      itemsToCompare={itemsToCompare}
      indexOfCompare={indexOfCompare}
      isFetching={isFetching}
      handleClose={handleClose}
      handleClick={handleClick}
      dataType={MINIMIZED_DATA.MINIMIZED_DOCS}
    />
  );
};
