import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { useAppSelector } from '../../store/hooks';
import { ChatErrorProps } from './ChatWindow.types';

export const ChatError = ({ errorSelector }: ChatErrorProps) => {
  const error = useAppSelector(errorSelector);

  return error ? (
    <div className='chat-error'>
      <WarningAmberOutlinedIcon /> <span>{error}</span>
    </div>
  ) : null;
};
