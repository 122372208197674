import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Modal } from '@mui/material';
import './ConfirmationModal.scss';
import { ConfirmationModalProps } from './ConfirmationModal.types';
import { ModalClose } from '../ModalClose';
import { ButtonWithIcon } from '../../ButtonWithIcon';

export const ConfirmationAgreementModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  successButtonIcon,
  successButtonLabel,
  cancelButtonLabel,
  disabled,
  className,
}) => {
  const [checked, setChecked] = useState<boolean>(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Modal open={open} className={`confirmation-modal-wrapper ${className}`}>
      <div className='confirmation-modal'>
        <ModalClose onClose={onClose} />
        <div className='confirmation-modal-header'>
          <div className='confirmation-modal-title'>{title}</div>
          <div className='confirmation-modal-message'>{message}</div>
          <FormControlLabel
            className='confirmation-modal-message'
            control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
            label='I agree to the terms and conditions'
          />
        </div>
        <div>
          <ButtonWithIcon className='cancel-button' onClick={onClose} disabled={disabled}>
            {cancelButtonLabel ?? 'Cancel'}
          </ButtonWithIcon>
          <ButtonWithIcon
            className='confirm-button'
            startIcon={successButtonIcon}
            disabled={!checked}
            onClick={() => {
              onConfirm();
            }}
          >
            {successButtonLabel}
          </ButtonWithIcon>
        </div>
      </div>
    </Modal>
  );
};
