import { Outlet, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { RoutePath } from '../../services/route.service';
import { useUserAccessContext } from '../../contexts/UserAccess';
import { useGetUserAdminQuery, useGetUserQuery } from '../../contexts/authorizarion.service';
import { useSnackbar } from 'notistack';
import { ErrorMessages } from '../../services/errors.service.types';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { mergeAuthResponses } from './auth.helpers';

export const RequireAuth = ({ children }: { children?: JSX.Element }) => {
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);

  const { checkUserAccess } = useUserAccessContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [session, setSession] = useState<CognitoUserSession>();
  const [error, setError] = useState(false);
  useEffect(() => {
    Auth.currentSession()
      .then((data) => setSession(data))
      .catch(() => setError(true));
  }, []);
  useEffect(() => {
    if (error) navigate(RoutePath.LOGIN);
  }, [error, navigate]);

  const {
    data: userGwr,
    isLoading: isLoadingGwr,
    error: errorGwr,
  } = useGetUserQuery(undefined, { skip: !session });
  const {
    data: userAdmin,
    isLoading: isLoadingAdmin,
    error: errorAdmin,
  } = useGetUserAdminQuery(undefined, { skip: !session });

  useEffect(() => {
    if (session && !isLoadingGwr && !isLoadingAdmin) {
      if ((errorGwr && errorAdmin) || (!userGwr && !userAdmin)) {
        !isSnackbarShown &&
          enqueueSnackbar(ErrorMessages.AuthenticationError, {
            variant: 'error',
            persist: true,
          });
        setIsSnackbarShown(true);
      } else {
        checkUserAccess(mergeAuthResponses(userGwr, userAdmin));
      }
      setCheckingAuth(false);
    }
  }, [
    isSnackbarShown,
    enqueueSnackbar,
    checkUserAccess,
    userGwr,
    userAdmin,
    isLoadingGwr,
    isLoadingAdmin,
    errorGwr,
    errorAdmin,
    session,
  ]);

  if (checkingAuth) {
    return (
      <Backdrop sx={{ color: '#fff' }} open={true}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  } else {
    return children ? children : <Outlet />;
  }
};
