import { Tabs } from '../StaticComponents/Tabs/Tabs';
import { Paper } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { useCustomPageLeavePrompt } from '../StaticComponents/Modals/ConfirmationModal/CustomPageLeavePrompt.hook';
import { TAB_NAMES } from '../../config/config';
import { RoutePath } from '../../services/route.service';
import { ClausesList } from '../Clauses/ClausesList';
import { DocumentsSearch } from '../Documents/DocumentsSearch/DocumentsSearch';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import { SearchButton } from './SearchButton';
import { useSearchButtonHook } from './SearchButton.helpers';
import { PendingItemsListGrid } from './PendingItemsListGrid/PendingItemsListGrid';
import { COLUMNS_DEFINITION } from './PendingItemsListGrid/PendingItemsListGrid.helpers';
import { BulkDeleteModal } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal';
import { useBulkDeleteACHClauses } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal.hooks';
import { DocumentsCount } from '../Documents/DocumentsCount';
import { UPLOADED_CLAUSES_CONFIG, UploadedClausesConfig } from './UploadedClauses.helpers';

export const UploadedClauses = ({
  config: {
    basePath,
    exportButtonEnabled,
    idPath,
    menuOptions,
    title,
    useAccess,
    useGetDataListWithParams,
    useGetPendingClausesListWithParams,
    viewModalMenuItems,
  } = UPLOADED_CLAUSES_CONFIG,
}: {
  config?: UploadedClausesConfig;
}) => {
  useAccess();
  const { filters, setFilters } = useGetDataListWithParams();
  const unsavedChanges = useAppSelector(uiSelectors.selectUnsavedChanges);
  useCustomPageLeavePrompt(unsavedChanges);
  const searchButtonProps = useSearchButtonHook();
  const { showSearch, isPendingTab } = searchButtonProps;

  return (
    <div className='my-files'>
      <Paper elevation={0} className='my-files-header'>
        <span className='my-files-title'>{title}</span>
        <div>
          <SearchButton {...searchButtonProps} />
          <BulkDeleteModal handler={useBulkDeleteACHClauses} />
        </div>
      </Paper>

      {showSearch && (
        <DocumentsSearch
          filters={filters}
          onApply={setFilters}
          accessMode={AccessMode.WRITE}
          clauseMode
        />
      )}
      <DocumentsCount
        useGetDataListWithParams={useGetDataListWithParams}
        clauseMode
        disabled={isPendingTab}
      />
      <Tabs
        base={basePath}
        idPath={idPath}
        components={[
          {
            label: TAB_NAMES.LIST_VIEW,
            url: RoutePath.UPLOADED_CLAUSES_LIST_VIEW,
            component: (
              <ClausesList
                menuOptions={menuOptions}
                useGetDataListWithParams={useGetDataListWithParams}
                viewModalMenuItems={viewModalMenuItems}
                exportButtonEnabled={exportButtonEnabled}
              />
            ),
          },
          {
            label: TAB_NAMES.PENDING,
            url: RoutePath.UPLOADED_CLAUSES_PENDING,
            component: (
              <Paper elevation={0} className='documents-list'>
                <PendingItemsListGrid
                  dataType='Clauses'
                  rowIdKey='ClauseId'
                  columnsDefinition={COLUMNS_DEFINITION('clause')}
                  dataHook={useGetPendingClausesListWithParams!}
                />
              </Paper>
            ),
            disabled: !useGetPendingClausesListWithParams,
          },
        ]}
      />
    </div>
  );
};
