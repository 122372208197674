import React from 'react';
import './ChatWindow.scss';
import gwrLogo from '../../assets/icons/gwr_logo.svg';
import { ChatMessageStream } from './ChatMessageStream';
import { ChatQuestionInput } from './ChatQuestionInput';
import { ChatLoading } from './ChatLoading';
import { useAppDispatch } from '../../store/hooks';
import { askQuestion } from '../../store/files/chat/documentChat.slice';
import { documentChatSelectors } from '../../store/files/chat/documentChat.selectors';
import { ChatError } from './ChatError';
import { ChatWindowProps } from './ChatWindow.types';
import { ChatCleanupButton } from './ChatCleanupButton';

const ChatWindow = ({ documentId: DocumentId }: ChatWindowProps) => {
  const dispatch = useAppDispatch();

  const handleSubmit = (Question: string, IdModel: string) => {
    dispatch(askQuestion({ DocumentId, IdModel, Question }));
  };

  return (
    <div className='chat-container'>
      <div className='chat-header'>
        <img src={gwrLogo} alt='GWR Logo' className='chat-logo' />
        <span>AI Assistant</span>
      </div>

      <ChatMessageStream messagesSelector={documentChatSelectors.selectMessages} />

      <ChatError errorSelector={documentChatSelectors.selectError} />

      <ChatLoading loadingSelector={documentChatSelectors.selectSpinner} />

      <div className='llm-question-input-container'>
        <ChatCleanupButton loadingSelector={documentChatSelectors.selectLoading} />

        <ChatQuestionInput
          loadingSelector={documentChatSelectors.selectLoading}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default ChatWindow;
