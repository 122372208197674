import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React from 'react';
import { Modal } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import common from '../../../assets/common.module.scss';

export const Loader = () => {
  const showLoader = useAppSelector(uiSelectors.selectShowLoader);

  return (
    <Modal open={showLoader} style={{ zIndex: common.zIndexMuiSnackbar }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          outline: 'none',
        }}
      >
        <CircularProgress sx={{ color: 'white' }} />
      </Box>
    </Modal>
  );
};
