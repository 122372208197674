import { Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';

export const ADD_PREVIOUS_VERSION_MODE = 'add_previous_version_mode';

export type CloseDocumentViewModalFnType = (
  dispatch: Dispatch,
  navigate: ReturnType<typeof useNavigate>,
  id: string | undefined,
  document: DocumentResponse
) => void;
