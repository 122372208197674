import React, { useEffect, useMemo, useState } from 'react';
import '../DocumentBar/DocumentBarList.scss';
import { useAppDispatch } from '../../store/hooks';
import { showModal } from '../../store/ui/ui.slice';
import { MINIMIZED_DATA, MinimizedData } from '../../store/ui/ui.types';
import { useGetClausesListQuery } from '../../store/files/clauses/clauses.list.service';
import { closeMinimizedClause } from '../../store/ui/ui.actions';
import { BarList } from '../DocumentBar/BarList';
import { openClauseViewModal } from '../../store/files/clauses/clauses.slice';
import { useIndexOfCompare } from '../../store/ui/ui.helpers';
import { ClauseBarListProps } from './ClauseBarList.types';

const CLAUSE_NOT_EXIST_MSG =
  'The Clause you have selected is not available anymore in Global Wording Repository.';
export const CLAUSE_NOT_EXIST_TITLE = 'Clause no longer exists';

export const ClauseBarList: React.FC<ClauseBarListProps> = ({ minimizedData, itemsToCompare }) => {
  const [clickedClauseId, setClickedClauseId] = useState<string>('');
  const [getClause, setGetClause] = useState(false);
  const dispatch = useAppDispatch();
  const { data, isSuccess, isFetching, isError } = useGetClausesListQuery(
    { ClauseId: [clickedClauseId] },
    {
      skip: !getClause,
    }
  );

  const clauses = useMemo(() => (data ? data.Clauses : []), [data]);

  const handleClose = (dataId: MinimizedData['dataId']) => (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    dispatch(closeMinimizedClause([dataId]));
  };

  const handleClick = (dataId: MinimizedData['dataId']) => () => {
    setClickedClauseId(dataId);
    setGetClause(true);
  };

  useEffect(() => {
    if (getClause) {
      const clause = clauses[0];

      const clauseExists = !isFetching && isSuccess && clause;
      const clauseNotExists = !isFetching && !isError && !clause;
      if (clauseExists) {
        setGetClause(false);
        dispatch(openClauseViewModal({ clause: clause }));
      } else if (clauseNotExists) {
        setGetClause(false);
        dispatch(showModal({ title: CLAUSE_NOT_EXIST_TITLE, reason: CLAUSE_NOT_EXIST_MSG }));
        dispatch(closeMinimizedClause([clickedClauseId]));
      }
    }
  }, [isSuccess, isFetching, isError, dispatch, clauses, getClause, clickedClauseId]);

  const indexOfCompare = useIndexOfCompare(itemsToCompare);

  return (
    <BarList
      minimizedData={minimizedData}
      itemsToCompare={itemsToCompare}
      indexOfCompare={indexOfCompare}
      isFetching={isFetching}
      handleClose={handleClose}
      handleClick={handleClick}
      dataType={MINIMIZED_DATA.MINIMIZED_CLAUSES}
    />
  );
};
