import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';

export const ChatTermsAndConditions = (
  <div className='chat-agreement-modal__terms_and_cond'>
    <p>
      <span>
        <WarningIcon className='warning-icon' />
        To use the chat, you must accept the terms and conditions.
      </span>
    </p>
    <span>1. As a user, you are responsible for the output: Check the results carefully.</span>
    <span>
      2. GWR AI Assistant can give wrong or imaginary answers. Check also for potential issues with
      copyrights as you are responsible for the usage of the texts.
    </span>
    <span>3. Don’t use manipulative prompts for unethical purposes.</span>
    <span>4. Your input and the results will not be saved.</span>
    <span>
      5. Please note that the use of the GWR AI Assistant is associated with additional costs.
      Therefore, we kindly ask you to use functionality responsibly.
    </span>
  </div>
);
