import { RoutePath } from '../../services/route.service';

export interface MinimizedData {
  dataId: string;
  name: string;
  comparable?: boolean;
}

export enum MINIMIZED_DATA {
  MINIMIZED_DOCS = 'MINIMIZED_DOCS',
  MINIMIZED_CLAUSES = 'MINIMIZED_CLAUSES',
}

export enum ITEM_TYPE {
  DOCUMENT = 'DOCUMENT',
  CLAUSE = 'CLAUSE',
  BOOKMARK = 'BOOKMARK',
  REPORT = 'REPORT',
}

export const CLOSE_DOC_FROM_MINIMIZED_REJECT_MSG = 'No such a document in minimized';
export const REMOVE_DOC_FROM_COMPARE_REJECT_MSG = 'No such a document in compare';
export const REMOVE_DOCS_FROM_COMPARE_REJECT_MSG = 'No such a documents in compare';

export interface ErrorModalProps {
  title: string;
  reason: string;
  label?: string;
  closeAction?: RoutePath;
}

export enum FILE_EXTENSIONS {
  CSV = '.csv',
  PDF = '.pdf',
}

export type SelectDocsPayload = {
  documentIds: string[];
  documentsRelatedClausesIds?: string[];
  isDraftDocuments?: boolean;
};
