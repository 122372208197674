import {
  ClauseDictionariesResponse,
  DictionariesResponse,
} from '../../../store/files/upload/list.service.types';
import { SequentialSearch } from '../../../store/files/documentsAndClauses/list.types';
import { notEmptySearchQuery } from '../DocumentsSearch/DocumentsSearch.helpers';
import { ClauseMetadataFields } from '../../UploadClause/UploadClause.types';
import {
  clearSelectedText,
  closeDocumentViewModal,
} from '../../../store/files/documents/documents.slice';
import { setScrollToClauseInDoc } from '../../../store/ui/ui.slice';
import { handleCloseItemViewModal } from '../../../store/ui/ui.helpers';
import { CloseDocumentViewModalFnType } from './DocumentViewModal.types';
import { getDocumentPath } from './DocumentRelatedClauses/ClauseRelatedDocuments.hooks';

export const hasWriteAccessToDoc = (
  entity: string,
  classification: string,
  writeAccessDictionaries: ClauseDictionariesResponse | DictionariesResponse
): boolean => {
  const entitiesDictionary =
    ClauseMetadataFields.Entities in writeAccessDictionaries
      ? writeAccessDictionaries.Entities
      : writeAccessDictionaries.Entity;

  if (!entitiesDictionary) {
    return false;
  }

  const isEntityIncluded = entitiesDictionary.values.some((e) => e.value === entity);
  const entityClassifications = entitiesDictionary.values.find(({ value }) => value === entity)
    ?.related?.Classification;
  const isClassificationIncluded = entityClassifications?.some(
    (entityClassification) => entityClassification === classification
  );
  return isEntityIncluded && !!isClassificationIncluded;
};

export const getLastNotEmptyQuery = (queries?: SequentialSearch[]) => {
  const notEmptyQueries = queries?.filter(notEmptySearchQuery);
  return notEmptyQueries?.[notEmptyQueries.length - 1] || {};
};

const commonCloseDocumentViewModalLogic: CloseDocumentViewModalFnType = (
  dispatch,
  navigate,
  id,
  { IsDraft }
) => {
  dispatch(clearSelectedText());
  dispatch(setScrollToClauseInDoc(undefined));
  if (id) {
    navigate(getDocumentPath(IsDraft));
  }
};

export const handleCloseDocumentViewModal: CloseDocumentViewModalFnType = (
  dispatch,
  navigate,
  id,
  document
) => {
  handleCloseItemViewModal(dispatch, document);
  commonCloseDocumentViewModalLogic(dispatch, navigate, id, document);
};

export const handleCloseDocumentViewModalOnly: CloseDocumentViewModalFnType = (
  dispatch,
  navigate,
  id,
  document
) => {
  dispatch(closeDocumentViewModal());
  commonCloseDocumentViewModalLogic(dispatch, navigate, id, document);
};
