import React from 'react';
import { Modal } from '@mui/material';
import './ConfirmationModal.scss';
import { ConfirmationModalProps } from './ConfirmationModal.types';
import { ModalClose } from '../ModalClose';
import { ButtonWithIcon } from '../../ButtonWithIcon';
import { TEST_ID } from '../../../../config/test-fields-ids.config';

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  successButtonIcon,
  successButtonLabel,
  cancelButtonLabel,
  disabled,
}) => {
  return (
    <Modal open={open} className='confirmation-modal-wrapper'>
      <div
        className='confirmation-modal'
        data-test-id={TEST_ID.UPLOAD_FILES.DELETE_UPLOADED_CONFIRM}
      >
        <ModalClose onClose={onClose} />
        <div className='confirmation-modal-header'>
          <div className='confirmation-modal-title'>{title}</div>
          <div
            className='confirmation-modal-message'
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>
        </div>
        <div>
          <ButtonWithIcon className='cancel-button' onClick={onClose} disabled={disabled}>
            {cancelButtonLabel ?? 'Cancel'}
          </ButtonWithIcon>
          <ButtonWithIcon
            className='confirm-button'
            startIcon={successButtonIcon}
            disabled={disabled}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {successButtonLabel}
          </ButtonWithIcon>
        </div>
      </div>
    </Modal>
  );
};
