import CompareIcon from '@mui/icons-material/Compare';
import { MenuItem } from '@mui/material';
import { useAppDispatch } from '../../../store/hooks';
import { DocumentContextMenuItemProps } from '../DocumentContextMenu/DocumentContextMenu.types';
import { openDocumentFilesCompareModal } from '../../../store/files/documents/documents.slice';
import { isDocumentResponse } from '../../../store/files/documentsAndClauses/list.helpers';

export const DocumentFilesCompareContextMenuButton = ({
  document,
  onClose,
}: DocumentContextMenuItemProps) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (isDocumentResponse(document)) {
      dispatch(openDocumentFilesCompareModal({ document }));
    }
    onClose();
  };

  return (
    <MenuItem onClick={handleClick} style={{ fontStyle: 'italic' }}>
      <CompareIcon />
      Compare document files
    </MenuItem>
  );
};
