import React, { useEffect, useRef } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { ClauseMetadata, ClauseMetadataFields } from '../../UploadClause/UploadClause.types';
import SpecialCharacterTextEditor from './SpecialCharacterTextEditor';
import { FileLoader } from '../../StaticComponents/FileViewer/FileLoader';
import './SpecialCharacterTextEditor.scss';
import { useDocumentDownload } from '../../StaticComponents/FileViewer/useDocumentDownload';

interface SpecialCharacterTextEditorWithDownloadProps {
  url: string | undefined;
  loading: boolean;
  error: boolean;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setValue: UseFormSetValue<ClauseMetadata>;
}

const SpecialCharacterTextEditorWithDownload: React.FC<
  SpecialCharacterTextEditorWithDownloadProps
> = ({ url, loading, error, value, onChange, setValue }) => {
  const isInitialized = useRef(false);

  const { documentData, isDownloading, isError } = useDocumentDownload(loading, error, url);

  useEffect(() => {
    if (!isInitialized.current && documentData) {
      setValue(ClauseMetadataFields.Content, documentData, {
        shouldValidate: true,
        shouldDirty: false,
      });
      isInitialized.current = true;
    }
  }, [documentData, setValue]);

  if (isDownloading || loading || isError) {
    return <FileLoader loading={isDownloading} error={isError} />;
  }

  return <SpecialCharacterTextEditor value={value} onChange={onChange} />;
};

export default SpecialCharacterTextEditorWithDownload;
