import {
  AdminPanelCreatedUser,
  AdminPanelUser,
  GroupMetadata,
} from '../../store/adminPanel/adminPanel.types';
import { SetValueType } from './CreateUserPanel/CreateUserPanel.types';

export const filterUserGroupsValues = (
  selectedEntity: string | undefined,
  selectedGroups: string[],
  userGroupsMetadata: GroupMetadata[] = [],
  setValue: SetValueType<AdminPanelCreatedUser | AdminPanelUser>
): GroupMetadata[] => {
  if (selectedEntity) {
    const allEntityMatchingGroups = userGroupsMetadata?.filter((group) =>
      group.Entity.includes(selectedEntity)
    );

    const intersectingGroups =
      allEntityMatchingGroups
        ?.map((group) => group.GroupName)
        .filter((groupName) => selectedGroups.includes(groupName)) ?? [];

    const groupsNotMatchingSelectedEntity = allEntityMatchingGroups
      ?.map((group) => group.GroupName)
      .filter((groupName) => !selectedGroups.includes(groupName));

    if (
      selectedGroups.length &&
      selectedGroups.length > intersectingGroups?.length &&
      groupsNotMatchingSelectedEntity?.length &&
      intersectingGroups?.length
    ) {
      setValue('UserGroups', intersectingGroups);
    } else if (
      selectedGroups.length &&
      !intersectingGroups?.length &&
      groupsNotMatchingSelectedEntity?.length
    ) {
      setValue('UserGroups', []);
    }

    return allEntityMatchingGroups;
  } else {
    return userGroupsMetadata;
  }
};

export const sortUsersGroups = (usersGroups?: GroupMetadata[]): GroupMetadata[] | undefined => {
  return usersGroups?.slice().sort((a, b) => a.GroupName.localeCompare(b.GroupName));
};

export const sortStrings = (strings: string[]): string[] => {
  return strings.slice().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
};
