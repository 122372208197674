import { DOCUMENT_CONTEXT_MENU_ITEM_NAME, ContextMenuItem } from './DocumentContextMenu.types';
import { documentsListApi } from '../../../store/files/documents/documents.list.service';
import {
  DOCUMENTS_LIST_API_ENDPOINTS,
  DOCUMENTS_LIST_API_TAGS,
  DocumentStatus,
} from '../../../store/files/documents/documents.list.types';
import { getOriginalArgs, getOriginalArgsClause } from './DocumentContextMenuDelete.helpers';
import { clausesListApi } from '../../../store/files/clauses/clauses.list.service';
import {
  CLAUSES_LIST_API_ENDPOINTS,
  CLAUSES_LIST_API_TAGS,
} from '../../../store/files/clauses/clauses.list.types';
import { EXPERIMENTAL_ENABLED } from '../../../config/config';

export const MenuItemsDocuments: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.BOOKMARK, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.REPORT, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.ADD_NEW_VERSION, disabled: false },
];

export const MenuItemsDocumentViewModal: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.REPORT, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.SHOW_RELATED, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.BOOKMARK, disabled: false },
];

export const MenuItemsDocumentReportedViewModal: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.BOOKMARK, disabled: false },
];

export const MenuItemsUploadedDocuments: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.BOOKMARK, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.REPORT, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.ADD_NEW_VERSION, disabled: false },
];

export const MenuItemsReportedDocuments: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE, disabled: false },
];

export const MenuItemsReportedReadDocuments: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: false },
];

export const MenuItemsClauses: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.BOOKMARK, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.REPORT, disabled: false },
];

export const MenuItemsClauseViewModal: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.REPORT, disabled: false },
];

export const MenuItemsClauseViewModalEdit: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.CLAUSE_VIEW_EDIT, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.REPORT, disabled: false },
];

export const MenuItemsClauseReportedViewModal: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.CLAUSE_VIEW_EDIT, disabled: false },
];

export const MenuItemsUploadedClauses: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.BOOKMARK, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.REPORT, disabled: false },
];

export const MenuItemsReportedClauses: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE, disabled: false },
];

export const MenuItemsBookmark: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.REMOVE_FROM_BOOKMARK, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.REPORT, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.MOVE, disabled: true },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.ADD_NEW_VERSION, disabled: false },
];

export const MenuItemsBookmarkDocuments: ContextMenuItem[] = [
  ...MenuItemsBookmark,
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: false },
];

export const MenuItemsDrafts: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE, disabled: false },
];

export const showDeletingStatusACH = (DocumentId: string) =>
  documentsListApi.util.updateQueryData(
    DOCUMENTS_LIST_API_ENDPOINTS.GET_DOCUMENTS_LIST,
    getOriginalArgs(
      DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST,
      DOCUMENTS_LIST_API_ENDPOINTS.GET_DOCUMENTS_LIST
    ),
    (documents) => {
      const document = documents.Documents.find((doc) => doc.DocumentId === DocumentId);
      if (document) {
        document.Status = DocumentStatus.DELETING;
      }
    }
  );

export const showDeletingStatusClausesACH = (ClauseId: string) =>
  clausesListApi.util.updateQueryData(
    CLAUSES_LIST_API_ENDPOINTS.GET_CLAUSES_LIST,
    getOriginalArgsClause(
      CLAUSES_LIST_API_TAGS.CLAUSES_LIST,
      CLAUSES_LIST_API_ENDPOINTS.GET_CLAUSES_LIST
    ),
    (clauses) => {
      const clause = clauses.Clauses.find((doc) => doc.ClauseId === ClauseId);
      if (clause) {
        clause.Status = DocumentStatus.DELETING;
      }
    }
  );

export const menuItemDisabled = (documentContextMenuItems: ContextMenuItem[], menuItem: string) => {
  return documentContextMenuItems.some((i) => i.name === menuItem && i.disabled);
};
export const menuItemVisible = (documentContextMenuItems: ContextMenuItem[], menuItem: string) => {
  return documentContextMenuItems.some((i) => i.name === menuItem);
};

if (EXPERIMENTAL_ENABLED) {
  const item = { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.FILES_COMPARE, disabled: false };
  MenuItemsDocuments.push(item);
  MenuItemsUploadedDocuments.push(item);
  MenuItemsBookmarkDocuments.push(item);
  MenuItemsDrafts.push(item);
}
