import { useAppDispatch } from '../../../store/hooks';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { useGetClausesListQuery } from '../../../store/files/clauses/clauses.list.service';
import { DocumentStatus } from '../../../store/files/documents/documents.list.types';
import { openClauseViewModal } from '../../../store/files/clauses/clauses.slice';
import { getRoutePath, RoutePath } from '../../../services/route.service';
import { ClausesListParams } from '../../../store/files/clauses/clauses.list.types';
import { formatBoolean } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { useEffect, useMemo } from 'react';
import { showLoader, showModal } from '../../../store/ui/ui.slice';
import { getClausePath } from '../../Documents/DocumentViewModal/DocumentRelatedClauses/ClauseRelatedDocuments.hooks';
import { CLAUSE_NOT_EXIST_TITLE } from '../ClauseBarList';

export const SANDBOX_CLAUSES_ID_PATH = getRoutePath(RoutePath.SANDBOX_CLAUSES, RoutePath.ID);

const CLAUSE_NOT_EXIST_MSG = 'The Clause is not available anymore in Global Wording Repository.';

const getErrorModalPayload = (isDraft: boolean) => ({
  title: CLAUSE_NOT_EXIST_TITLE,
  reason: CLAUSE_NOT_EXIST_MSG,
  closeAction: getClausePath(isDraft),
});

export const useFetchAndDispatchClause = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { id } = useParams();

  const isDraft = useMemo(() => !!matchPath(SANDBOX_CLAUSES_ID_PATH, pathname), [pathname]);

  const queryParameters: ClausesListParams = {
    ClauseId: [id ?? ''],
    IsDraft: formatBoolean(isDraft),
    Status: [DocumentStatus.READY],
  };
  const { data: clauses, isLoading } = useGetClausesListQuery(queryParameters, { skip: !id });

  useEffect(() => {
    setTimeout(() => {
      dispatch(showLoader(isLoading));
    });
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (id && clauses) {
      const clause = clauses.Clauses.find(({ ClauseId }) => ClauseId === id);
      if (clause) {
        dispatch(openClauseViewModal({ clause }));
      } else {
        dispatch(showModal(getErrorModalPayload(isDraft)));
      }
    }
  }, [clauses, dispatch, id, isDraft]);
};
