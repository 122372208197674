import { LinearProgress } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { ChatLoadingProps } from './ChatWindow.types';

export const ChatLoading = ({ loadingSelector }: ChatLoadingProps) => {
  const loading = useAppSelector(loadingSelector);

  return loading ? (
    <div>
      <LinearProgress />
    </div>
  ) : null;
};
