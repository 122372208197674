import {
  DOCUMENT_ID_PATH,
  DOCUMENT_ID_SIMILAR_CLAUSE_ID_PATH,
  getRoutePath,
  RoutePath,
  SANDBOX_DOCUMENTS_ID_PATH,
  SANDBOX_DOCUMENTS_ID_SIMILAR_CLAUSE_ID_PATH,
} from '../../../../services/route.service';
import { DocumentResponse } from '../../../../store/files/documents/documents.list.types';
import { ClauseResponse } from '../../../../store/files/clauses/clauses.list.types';
import { generatePath } from 'react-router-dom';

export const getDocumentPath = (IsDraft: boolean) =>
  IsDraft ? RoutePath.SANDBOX_DOCUMENTS : RoutePath.DOCUMENTS;

const getDocumentIdPath = (IsDraft: boolean, similar?: boolean) => {
  if (!similar) return IsDraft ? SANDBOX_DOCUMENTS_ID_PATH : DOCUMENT_ID_PATH;
  return IsDraft ? SANDBOX_DOCUMENTS_ID_SIMILAR_CLAUSE_ID_PATH : DOCUMENT_ID_SIMILAR_CLAUSE_ID_PATH;
};

export const openNewDocumentViewModal = (
  { DocumentId, IsDraft }: DocumentResponse,
  similarClauseId?: string
) => {
  const docPath = getDocumentIdPath(IsDraft, !!similarClauseId);
  window.open(generatePath(docPath, { id: DocumentId, clause_id: similarClauseId }), '_blank');
};

export const getClausePath = (IsDraft: boolean) =>
  IsDraft ? RoutePath.SANDBOX_CLAUSES : RoutePath.CLAUSES;

export const openNewClauseViewModal = ({ ClauseId, IsDraft }: ClauseResponse) => {
  window.open(getRoutePath(getClausePath(IsDraft), ClauseId as RoutePath), '_blank');
};
