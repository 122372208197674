import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { Chip, Tooltip } from '@mui/material';
import { AdminPanelMetadata, EntityMetadata } from '../../../store/adminPanel/adminPanel.types';
import { sortStrings } from '../AdminPanel.helpers';

const getMetadataLabelByValue = (entities: EntityMetadata[], entityCode: string) => {
  return entities?.find(({ value }) => value === entityCode)?.label;
};
export const ManageUsersGroupsColumns = (metadata: AdminPanelMetadata): GridColDef[] => [
  {
    field: 'FamilyName',
    headerName: 'User',
    width: 240,
    renderCell: ({ row: { Name, FamilyName } }: GridRenderCellParams) => {
      return `${Name || ''} ${FamilyName || ''}`;
    },
  },
  {
    field: 'Email',
    headerName: 'Email',
    width: 300,
    renderCell: ({ row: { Email } }: GridRenderCellParams) => {
      return (
        <Tooltip title={Email} placement='top-start'>
          <span>{Email}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'Active',
    headerName: 'Status',
    minWidth: 50,
    renderCell: ({ row: { Active } }: GridRenderCellParams) => {
      return Active ? (
        <Chip label='ACTIVE' size='small' className='chip-status--active' />
      ) : (
        <Chip label='INACTIVE' size='small' className='chip-status' />
      );
    },
  },
  {
    field: 'Entity',
    headerName: 'Company',
    width: 300,
    renderCell: ({ row: { Entity } }: GridRenderCellParams) => {
      const entityLabel = getMetadataLabelByValue(metadata?.Entities, Entity) ?? '';
      return (
        <Tooltip title={entityLabel} placement='top-start'>
          <span>{entityLabel}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'UserGroups',
    headerName: 'Groups',
    width: 640,
    sortable: false,
    renderCell: ({ row: { UserGroups } }: GridRenderCellParams) => {
      const userGroupsSorted = sortStrings(UserGroups);
      return (
        <Tooltip
          title={userGroupsSorted.map((UserGroup: string) => (
            <span key={UserGroup}>{UserGroup}</span>
          ))}
          placement='top-start'
        >
          <span>{userGroupsSorted.join(', ')}</span>
        </Tooltip>
      );
    },
  },
];
