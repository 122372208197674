import React from 'react';
import SpecialCharacterTextField from './SpecialCharactersTextField';
import './SpecialCharacterTextEditor.scss';

interface SpecialCharacterTextEditorProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SpecialCharacterTextEditor: React.FC<SpecialCharacterTextEditorProps> = ({ ...props }) => {
  return (
    <div className='edit-clause-text-field '>
      <SpecialCharacterTextField {...props} label='' fullWidth multiline rows={10} />
    </div>
  );
};

export default SpecialCharacterTextEditor;
