import { IconButton, Tooltip } from '@mui/material';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import React from 'react';
import { clearChat } from '../../store/files/chat/documentChat.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ChatLoadingProps } from './ChatWindow.types';

export const ChatCleanupButton = ({ loadingSelector }: ChatLoadingProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(loadingSelector);

  const handleClearChat = () => {
    dispatch(clearChat());
  };

  return (
    <Tooltip title='Delete a conversation'>
      <IconButton
        className='llm-clear-button'
        disabled={loading}
        onClick={handleClearChat}
        type='button'
      >
        <DeleteOutlined />
      </IconButton>
    </Tooltip>
  );
};
