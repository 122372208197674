import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UploadedFile } from '../../../components/UploadFiles/UploadedFileList.types';
import {
  DELETE_ENDPOINT,
  DOCUMENT_DOWNLOAD_ENDPOINT,
  DOCUMENT_METADATA_ENDPOINT,
  LIST_ENDPOINT,
} from '../../../services/api.service.config';
import {
  ClauseDictionariesResponse,
  DictionariesParams,
  DictionariesResponse,
  DocumentDownloadResponse,
  LIST_API_ENDPOINTS,
  LIST_API_TAGS,
  UploadedFileRequest,
} from './list.service.types';
import { mapUploadedFileAction, mapUploadedFileEdit, mapUploadedFileType } from './upload.helpers';
import { appendAuthorizationHeader } from '../../../components/Auth/auth.helpers';
import { prepareQueryString } from '../documentsAndClauses/list.helpers';
import { timeoutPromise } from '../../../helpers/app.helpers';
import {
  disableAutomatedUpload,
  getExtendedClauseEntitiesRelatedValuesWith,
  getExtendedEntitiesRelatedValuesWith,
} from './list.helpers';
import { AccessMode } from '../documentsAndClauses/list.types';

const UPLOADED_FILE_REQUEST_CACHE_EXPIRATION = 3600;

const getUploadedFileTag = (id: string) => [{ type: LIST_API_TAGS.FILE, id }];

export const listApi = createApi({
  reducerPath: 'listApi',
  baseQuery: fetchBaseQuery({
    baseUrl: LIST_ENDPOINT,
    prepareHeaders: appendAuthorizationHeader,
  }),
  tagTypes: [LIST_API_TAGS.LIST, LIST_API_TAGS.FILE],
  endpoints: (builder) => ({
    [LIST_API_ENDPOINTS.GET_LIST]: builder.query<UploadedFile[], { isDraft?: boolean } | void>({
      query: (params) => {
        if (params && params.isDraft !== undefined) {
          return `?IsDraft=${params.isDraft}`;
        }
        return '';
      },
      providesTags: [LIST_API_TAGS.LIST],
      transformResponse: (response: UploadedFile[]): UploadedFile[] =>
        response
          ? response.map((document) => ({
              ...document,
              fileType: mapUploadedFileType(document.FileName),
              edit: mapUploadedFileEdit(document.Status),
              action: mapUploadedFileAction(document.Status),
            }))
          : [],
    }),
    getFile: builder.query<DocumentDownloadResponse, string>({
      query: (id) => DOCUMENT_DOWNLOAD_ENDPOINT.replace('{id}', id),
      keepUnusedDataFor: UPLOADED_FILE_REQUEST_CACHE_EXPIRATION,
      providesTags: (result, error, id) => getUploadedFileTag(id),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        await timeoutPromise(UPLOADED_FILE_REQUEST_CACHE_EXPIRATION * 1000);
        dispatch(listApi.util.invalidateTags(getUploadedFileTag(id)));
      },
    }),
    getDictionaries: builder.query<DictionariesResponse, DictionariesParams>({
      query: (values) =>
        `${DOCUMENT_METADATA_ENDPOINT}?${prepareQueryString(values)}&Type=document`,
      keepUnusedDataFor: 43200,
      transformResponse: (response: DictionariesResponse): DictionariesResponse => {
        if (response) {
          response.Entity.values = getExtendedEntitiesRelatedValuesWith(response);
        }
        return response;
      },
    }),
    getClauseDictionaries: builder.query<ClauseDictionariesResponse, DictionariesParams>({
      query: (values) => `${DOCUMENT_METADATA_ENDPOINT}?${prepareQueryString(values)}&Type=clause`,
      keepUnusedDataFor: 43200,
      transformResponse: (
        response: ClauseDictionariesResponse,
        meta,
        arg
      ): ClauseDictionariesResponse => {
        if (response) {
          if (arg.AccessMode === AccessMode.WRITE) {
            response.ClauseSources.values = disableAutomatedUpload(response);
          }
          response.Entities.values = getExtendedClauseEntitiesRelatedValuesWith(response);
        }
        return response;
      },
    }),
    updateMetadata: builder.mutation<void, UploadedFileRequest[]>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
      invalidatesTags: [LIST_API_TAGS.LIST],
    }),
    deleteFile: builder.mutation<void, string>({
      query: (id) => ({
        url: DELETE_ENDPOINT.replace('{id}', id),
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetListQuery,
  useLazyGetListQuery,
  useGetFileQuery,
  useGetDictionariesQuery,
  useGetClauseDictionariesQuery,
  useUpdateMetadataMutation,
  useDeleteFileMutation,
} = listApi;
